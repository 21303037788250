/**
 * DirectionMeter
 */

import clsx from 'clsx';
import React from 'react';
import { ProblemDynamicData } from 'types/pageTypes/area_page';

type Panel = keyof Omit<
	Props,
	| 'title'
	| 'className'
	| 'isInteractive'
	| 'onPanelClicked'
	| 'id'
	| 'height'
	| 'width'
>;
interface Props {
	id?: string;

	/** Label and State of East Block */
	eastPanel: ProblemDynamicData;

	/** Label and State of North Block */
	northPanel: ProblemDynamicData;

	/** Label and State of North East Block */
	northEastPanel: ProblemDynamicData;

	/** Label and State of North West Block */
	northWestPanel: ProblemDynamicData;

	/** Label and State of South Block */
	southPanel: ProblemDynamicData;

	/** Label and State of South East Block */
	southEastPanel: ProblemDynamicData;

	/** Label and State of South West Block */
	southWestPanel: ProblemDynamicData;

	/** Label and State of West Block */
	westPanel: ProblemDynamicData;

	/** Title for Screen Reader */
	title: string;

	/** Whether the panels of the direction meter should be interactive or not */
	isInteractive?: boolean;

	/** CSS Classes */
	className?: string;

	/** custom  sizes */
	height?: string;

	width?: string;

	/** Function for when Panel is Clicked */
	onPanelClicked?(panel: Panel): void;
}

/** Direction Meter showing distribution of spread. */
const DirectionMeter = ({
	id = undefined,
	eastPanel,
	northPanel,
	northEastPanel,
	northWestPanel,
	southPanel,
	southEastPanel,
	southWestPanel,
	westPanel,
	title,
	className,
	isInteractive = false,
	width,
	height,
	onPanelClicked,
}: Props) => {
	function executePanelCallback(panel: Panel) {
		if (onPanelClicked) onPanelClicked(panel);
	}

	return (
		<figure className={clsx(className)}>
			<svg
				data-type={isInteractive ? 'map-direction-meter' : 'direction-meter'}
				viewBox="0 0 218 236"
				height={height ? height : '150'}
				width={width}
				aria-hidden="true"
				aria-labelledby={title}
			>
				<title>{title}</title>
				<g>
					<clipPath id={`${id}-DirectionMeter_clip`}>
						<ellipse cx="108" cy="114.8" rx="88.4" ry="88.8" />
					</clipPath>
					<g clipPath="DirectionMeter_clip)">
						<polygon
							data-active-d-panel={northWestPanel?.state}
							points="49.9,55 43.5,85.2 105.6,111.6 79.6,49.2"
							onClick={() => executePanelCallback('northWestPanel')}
						/>
						<polygon
							data-active-d-panel={westPanel?.state}
							points="104.7,114.5 41.7,140.7 24.5,114.9 42.8,88.2"
							onClick={() => executePanelCallback('westPanel')}
						/>
						<polygon
							data-active-d-panel={southWestPanel?.state}
							points="105.9,117.3 80,179.7 48.6,174.8 43,143.5"
							onClick={() => executePanelCallback('southWestPanel')}
						/>
						<polygon
							data-active-d-panel={southPanel?.state}
							points="108.6,118.7 134.7,181.3 108.5,198.8 82.7,181.1"
							onClick={() => executePanelCallback('southPanel')}
						/>
						<polygon
							data-active-d-panel={southEastPanel?.state}
							points="111.3,117.3 174.1,144 168.2,173.6 137.3,179.8"
							onClick={() => executePanelCallback('southEastPanel')}
						/>
						<polygon
							data-active-d-panel={eastPanel?.state}
							points="112.5,114.6 173.7,89.1 191.6,115.3 174.7,141"
							onClick={() => executePanelCallback('eastPanel')}
						/>
						<polygon
							data-active-d-panel={northEastPanel?.state}
							points="111.4,111.7 137.1,49.8 167.7,54.8 172.2,86.5"
							onClick={() => executePanelCallback('northEastPanel')}
						/>
						<polygon
							data-active-d-panel={northPanel?.state}
							points="108.6,110.9 82.5,48.4 109.3,30.8 134.3,48.7"
							onClick={() => executePanelCallback('northPanel')}
						/>
					</g>
				</g>
				<g>
					{northWestPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="105.6,111.6 49.9,55 43.5,85.2"
							onClick={() => executePanelCallback('northWestPanel')}
						/>
					)}

					{westPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="104.7,114.5 24.5,114.9 41.7,140.7"
							onClick={() => executePanelCallback('westPanel')}
						/>
					)}

					{southWestPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="105.9,117.3 48.6,174.8 80,179.7"
							onClick={() => executePanelCallback('southWestPanel')}
						/>
					)}

					{southPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="108.6,118.7 108.5,198.8 134.7,181.3"
							onClick={() => executePanelCallback('southPanel')}
						/>
					)}

					{southEastPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="111.3,117.3 168.2,173.6 174.1,144"
							onClick={() => executePanelCallback('southEastPanel')}
						/>
					)}

					{eastPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="112.5,114.6 191.6,115.3 173.7,89.1"
							onClick={() => executePanelCallback('eastPanel')}
						/>
					)}

					{northEastPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="111.4,111.7 167.7,54.8 137.1,49.8"
							onClick={() => executePanelCallback('northEastPanel')}
						/>
					)}

					{northPanel?.state && (
						<polygon
							opacity="0.1"
							fill="#212120"
							points="108.6,110.9 109.3,30.8 82.5,48.4"
							onClick={() => executePanelCallback('northPanel')}
						/>
					)}
				</g>
				<path
					fill="#212121"
					d="M176.1,85.4l-4.9-34.1L138,45.9l-1.7-0.7l-26.9-19.2L80.5,45l-0.1,0l-33.9,6.6l-7.4,34.9
		l-19.5,28.4L39,143.8l6.2,34.5l34.1,5.3l29.2,19.9l29.9-19.9l33.2-6.6l6.9-34.8l17.9-26.9L176.1,85.4z M172.2,86.5l-60.7,25.3
		l25.7-61.9l30.5,5L172.2,86.5z M134.3,48.7l-25.8,62.2l-26-62.4l26.8-17.6L134.3,48.7z M49.9,55l29.7-5.8l26,62.4L43.5,85.2L49.9,55
		z M24.5,114.9l18.3-26.7l61.9,26.3l-63,26.2L24.5,114.9z M43,143.5l62.9-26.2L80,179.7l-31.4-4.9L43,143.5z M82.7,181.1l25.9-62.4
		l26.1,62.6l-26.2,17.5L82.7,181.1z M168.2,173.6l-30.9,6.2l-26.1-62.5l62.8,26.7L168.2,173.6z M174.8,140.6l-0.1,0.4l-62.2-26.5
		l61.2-25.5l17.9,26.2L174.8,140.6z"
				/>
				<g>
					{isInteractive && (
						<>
							<text
								transform="matrix(1 0 0 1 40 200)"
								textAnchor="end"
								fill="#333333"
							>
								{southWestPanel?.label}
							</text>

							<text
								transform="matrix(1 0 0 1 200 200)"
								textAnchor="end"
								fill="#333333"
							>
								{southEastPanel?.label}
							</text>

							<text
								transform="matrix(1 0 0 1 200 40)"
								textAnchor="end"
								fill="#333333"
							>
								{northEastPanel?.label}
							</text>
							<text
								transform="matrix(1 0 0 1 40 40)"
								textAnchor="end"
								fill="#333333"
							>
								{northWestPanel?.label}
							</text>
						</>
					)}
					<text
						transform="matrix(1 0 0 1 15 122.5669)"
						textAnchor="end"
						fill="#333333"
					>
						{westPanel?.label ?? 'W'}
					</text>
					<text
						transform="matrix(1 0 0 1 109 230)"
						textAnchor="middle"
						fill="#333333"
					>
						{southPanel?.label ?? 'S'}
					</text>
					<text
						transform="matrix(1 0 0 1 200 125)"
						textAnchor="start"
						fill="#333333"
					>
						{eastPanel?.label ?? 'E'}
					</text>
					<text
						transform="matrix(1 0 0 1 109 20)"
						textAnchor="middle"
						fill="#212121"
					>
						{northPanel?.label ?? 'N'}
					</text>
				</g>
			</svg>
		</figure>
	);
};

export default DirectionMeter;
