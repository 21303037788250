import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import MapContext from 'components/Map/MapContext';
import React, { useContext } from 'react';

import { Geolocation, Overlay } from 'ol';
import * as ol from 'ol';
import { Vector as SourceVector } from 'ol/source';
import { Style, Icon } from 'ol/style';
import Point from 'ol/geom/Point';
import VectorLayer from 'ol/layer/Vector';
import IconAnchorUnits from 'ol/style/IconAnchorUnits';
interface Props {
	title: string;
	handleClick?: () => void;
	myPositionMarkerIconUrl: string;
	active?: boolean;
}

const CenterUserControl: React.FC<Props> = ({
	title,
	handleClick,
	myPositionMarkerIconUrl,
	active = false,
}) => {
	const { map } = useContext(MapContext);

	function handleMyLocation() {
		const geolocation = new Geolocation({
			tracking: true,
			projection: map.getView().getProjection(),
			trackingOptions: {
				enableHighAccuracy: true,
			},
		});

		let alreadyShowingPosition = false;

		geolocation.on('change', () => {
			if (alreadyShowingPosition === false) {
				const position = geolocation.getPosition();
				const geometry = geolocation.getAccuracyGeometry();

				if (position && geometry) {
					map.getView().fit(geometry, {});
					map.getView().setZoom(10);

					var marker = new Overlay({
						id: 'myPositionMarker',
						position: position,
						stopEvent: false,
					});

					map.addOverlay(marker);

					const iconFeature = new ol.Feature({
						geometry: new Point(position),
						name: 'Min Position',
					});

					iconFeature.setStyle(
						new Style({
							image: new Icon({
								src: myPositionMarkerIconUrl,
								anchor: [0.5, 40],
								anchorXUnits: IconAnchorUnits.FRACTION,
								anchorYUnits: IconAnchorUnits.PIXELS,
							}),
						})
					);

					const layerSource = new SourceVector({});
					layerSource.addFeatures([iconFeature]);

					const vectorLayer = new VectorLayer({
						source: layerSource,
					});

					map.addLayer(vectorLayer);
				}
				alreadyShowingPosition = true;
			}
		});

		geolocation.on('error', (e) => {
			if (e.code === 1) {
				window.alert('You have to allow location access to use this feature.');
			}
		});
	}

	return (
		<BaseCustomControl
			icon="myPosition"
			title={title}
			handleControl={handleClick ?? handleMyLocation}
			className="ol-center-user"
			labelPosition="left"
			active={active}
		/>
	);
};

export default CenterUserControl;
