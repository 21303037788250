import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import initStore from 'store';
import { initialState as reactInitialState } from 'store/modules/react';
import { RenderingStates } from 'types/epi';
import Application from './App';

// Set default rendermethod and a basic model.
// eslint-disable-next-line
let renderMethod = ReactDOM.render;
let model = {
	head: {},
	epi: {},
	react: reactInitialState,
};

// Replace our basic model if one exitst on the window-object,
// then we also change rendermethod to hydrate.
if (window.__model) {
	// eslint-disable-next-line
	let renderMethod = ReactDOM.hydrate;
	model = window.__model;
	model.react.renderingState = RenderingStates.Hydrate;
}

let react = model.react;
delete model.react;

const preloadedState = {
	react,
	epi: model.epi,
	model: { model },
};

// Render the application to the DOM
const element = document.getElementById(react.containerId);
if (element) {
	renderMethod(
		<Provider store={initStore(preloadedState)}>
			<Application Router={BrowserRouter} />
		</Provider>,
		element
	);
}
