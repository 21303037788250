/**
 * Puff
 */

import React from 'react';
import { PuffWithImageModel } from 'types/blocks';

import Text from 'components/Text';
import Link from 'components/Link';
import Image from 'components/Image';

/** Puff with image, text and link. */
export const PuffWithImage: React.FC<PuffWithImageModel> = ({
	id,
	heading,
	image,
	url,
	linkText,
	text,
	_properties = {},
}) => {
	return (
		<div
			className="block p-4 break-words"
			id={id}
			key={id}
			{..._properties?.block}
		>
			<div {..._properties?.image}>
				<Image src={image} alt={heading} />
			</div>
			<Text as="h2" {..._properties?.heading}>
				{heading}
			</Text>
			<Text as="p" {..._properties?.text}>
				{text}
			</Text>

			{url && linkText && (
				<Link to={url} {..._properties?.url}>
					{linkText}
				</Link>
			)}

			{_properties?.linkText && (
				<Text as="p" {..._properties?.linkText}>
					{linkText}
				</Text>
			)}
		</div>
	);
};

export default PuffWithImage;
