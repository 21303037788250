/**
 * PageFooter
 */

import clsx from 'clsx';
import Image from 'components/Image';
import FooterColumn from 'components/PageFooter/FooterColumn';
import FooterMountain from 'components/PageFooter/FooterMountain';

import React, { useEffect } from 'react';
import { IImage as LavinImage, FooterColumnItems } from 'types/global';

interface Props {
	lavinImage?: LavinImage;
	/* Columns containing list of Page Links and/or other Content (like contact us info) */
	columns?: Array<{
		modelType: 'FooterColumnModel' | string;
		header: string;
		items: Array<FooterColumnItems>;
	}>;

	/* This is the Naturvardsverket Logo sent from the backend */
	logo?: {
		modelType: string;
		src: string;
		alt: string;
	};

	/* This is the Caption Text for the Logo sent from the backend */
	logoText?: string;
	copyright?: string;

	/* get the component height */
	fnFooterHeight: (val: number) => void;
}

/** Page Footer for Lavinprognoser */
const PageFooter: React.FC<Props> = ({
	lavinImage,
	columns,
	logo,
	copyright,
	logoText,
	fnFooterHeight,
}) => {
	const targetRef = React.useRef<HTMLDivElement>(null);
	useEffect(() => {
		if (targetRef.current) {
			//const isTablet = useMediaQuery({ query: '(max-width: 767px)' });
			// TODO fix mobile height
			fnFooterHeight(targetRef.current.offsetHeight);
		}
	}, []);
	return (
		<footer
			ref={targetRef}
			className="bg-grey-darkest mt-0 sm:mt-32 pt-4 w-full"
		>
			<FooterMountain className="main-w-container mx-auto z-10 relative -top-4 lg:-top-5 footer-p-responsive" />
			<div className="flex flex-col lg:flex-row -mt-12 sm:mt-0 mb-0 lg:mb-16 footer-img-and-col-p main-w-container mx-auto justify-between footer-p-responsive">
				{lavinImage && 
					<Image
						src={lavinImage.src}
						alt={lavinImage.alt}
						className="lavin-footer-logo footer-p-responsive"
						aria-label={lavinImage?.alt}
					/>
				}

				{columns !== undefined &&
					columns.map((col, index) => (
						<FooterColumn
							index={index}
							key={index}
							header={col.header}
							items={col.items}
						/>
					))}
			</div>

			<div className="bg-white w-full mt-10 flex justify-center footer-nv-logo md:mb-16">
				<div className="main-w-container w-full flex">
					{logo && (
						<Image
							src={logo.src}
							alt={logo.alt}
							width={65}
							height={73}
							className="h-20 footer-p-responsive"
							aria-label={logo?.alt}
						/>
					)}

					<div className="flex flex-col justify-between ml-4 h-20">
						{logoText && (
							<p className="font-standard leading-6 w-32">{logoText}</p>
						)}
						<p className="text-h6">{`${copyright}`}</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default PageFooter;
