/**
 * Layers
 */

import React from 'react';

type Props = {
	children?: any;
};

const Layers: React.FC<Props> = ({ children }) => <div>{children}</div>;

export default Layers;
