import MapContext from 'components/Map/MapContext';
import { Tile } from 'ol/layer';
import { get } from 'ol/proj';
import { TileWMS } from 'ol/source';
import React, { useContext, useEffect, useState } from 'react';

interface Props {
	layer: {
		name: 'SlopeInclination' | 'SlopeAspect' | 'Polygon' | 'ExtraPolygon';
		url: string;
		params: Array<{ parameter: string; value: string }>;
	};
	active: boolean;
}

const DecliningScaleLayer: React.FC<Props> = ({ layer, active }) => {
	const { map } = useContext(MapContext);
	const [layerTile, setLayerTile] = useState<Tile>();

	useEffect(() => {
		if (!map) return;

		const paramObjectList = layer.params.map((e) => ({
			[e.parameter]: e.value,
		}));

		const mergedParamObjectList = Object.assign({}, ...paramObjectList);

		const decliningGradeSource = new TileWMS({
			projection: get('EPSG:3857'),
			params: mergedParamObjectList,
			url: layer.url,
		});

		const tempLayerTile = new Tile({
			source: decliningGradeSource,
			visible: active,
		});

		setLayerTile(tempLayerTile);

		map.addLayer(tempLayerTile);
	}, [map, layer]);

	useEffect(() => {
		if (layerTile) layerTile.setVisible(active);
	}, [active]);

	return null;
};

export default DecliningScaleLayer;
