/**
 * RiskTable
 */
import DesktopRiskTable from 'components/RiskTable/DesktopRiskTable';
import MobileRiskTable from 'components/RiskTable/MobileRiskTable';
import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { TableRowsBlock } from 'types/blocks';
import { IImage } from 'types/global';

interface Props {
	/**Title for component */
	heading?: string;

	/**Lavin Logo image */
	image: IImage;

	/** Table Headings */
	tableHeadTexts: string[];

	/** Table Rows */
	tableRows: Array<TableRowsBlock>;
}

/** Table containing all the skill/risk ratings and what they mean. */
const RiskTable: React.FC<Props> = (props) => {
	const tab = useMediaQuery({
		minWidth: 768,
	});
	return (
		<>
			{tab && <DesktopRiskTable {...props} />}
			{!tab && <MobileRiskTable {...props} className="visible md:hidden" />}
		</>
	);
};

export default RiskTable;
