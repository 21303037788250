/**
 * GlossaryLetter
 */

import React from 'react';
import { smoothScrollToTarget } from 'utils/smoothScrollToTarget';

/** alphabet letter component. */
const GlossaryLetter: React.FC<any> = (props: any) => {
	// Access the alphabet prop
	const { alphabet, isEnglish } = props;

	return (
		<>
			{alphabet && (
				<li className="glossary-filter">
					<a
						href={`#${alphabet}`}
						onClick={smoothScrollToTarget}
						role="button"
						aria-label={
							isEnglish
								? `Jump to section letter ${alphabet}`
								: `Hoppa till avsnittsbokstaven ${alphabet}`
						}
						tabIndex={0}
						className="w-12 h-12 flex uppercase text-2xl items-center justify-center text-white font-semibold bg-orange hover:border-1 hover:border-black hover:bg-white hover:text-black focus:bg-white focus:text-black focus:border-1 focus:border-black "
					>
						{alphabet}
					</a>
				</li>
			)}
		</>
	);
};

export default GlossaryLetter;
