/**
 * NavigationPage
 */

import React from 'react';
import { NavigationPageModel } from 'types/pages';

import Breadcrumb from 'components/Breadcrumb';
import Card from 'components/Card';
import BannerImage from 'components/BannerImage';

/** Navigational page with listing. */
const NavigationPage: React.FC<NavigationPageModel> = ({
	heading,
	bannerImage,
	pages,
}) => {
	return (
		<article aria-labelledby="article-heading">
			{bannerImage && <BannerImage heading={heading} image={bannerImage} />}

			<div className="mx-auto main-w-container">
				<Breadcrumb className="mt-1 mb-6 pl-6" />

				<div className="w-full flex flex-wrap p-4 md-px-0">
					{pages.map((page, i) => {
						return (
							<Card
								image={page.image}
								heading={page.heading}
								body={page.body}
								url={page.url}
								id={page.id}
								icon={page.icon}
								key={page.id.toString() + i.toString()}
							/>
						);
					})}
				</div>
			</div>
		</article>
	);
};

export default NavigationPage;
