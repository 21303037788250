/**
 * Text
 */

import clsx from 'clsx';
import React from 'react';

type Tags =
	| 'h1'
	| 'h2'
	| 'h3'
	| 'h4'
	| 'h5'
	| 'h6'
	| 'p'
	| 'preamble'
	| 'span'
	| 'div'
	| 'screenReader';

export interface TextProps {
	/** The text to render (if no children are present) */
	text?: string;

	/** The html-tag/Component that will be outputted */
	as: Tags;

	/** The style that will be used (instead of the tag default style) */
	styleAs?: Tags;

	/** Option to remove default text margin */
	margin?: boolean;

	/** Pass optional classnames for the html */
	className?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

const tagMapper = {
	h1: 'h1',
	h2: 'h2',
	h3: 'h3',
	h4: 'h4',
	h5: 'h5',
	h6: 'h6',
	p: 'p',
	preamble: 'p',
	span: 'span',
	div: 'div',
	screenReader: 'div',
};

const typographyClasses: any = {
	h1: 'font-standard font-medium text-h1',
	h2: 'font-standard font-medium text-h2',
	h3: 'font-standard font-bold text-h3',
	h4: 'font-standard font-bold text-h4',
	h5: 'font-standard font-bold text-h5',
	h6: 'font-standard font-medium text-h6',
	p: 'font-standard text-p',
	preamble: 'font-alt font-medium text-preamble',
	span: 'font-standard',
	div: 'font-standard',
	screenReader: 'sr-only',
};

const typographyMarginClasses: any = {
	h1: 'mb-4',
	h2: 'mb-2',
	h3: 'mb-2',
	h4: 'mb-2',
	h5: 'mb-2',
	h6: 'mb-2',
	p: 'mb-4',
	preamble: 'mb-4',
	span: '',
	div: '',
	screenReader: '',
};

/** Text component to render texts, also has handling for Epi server on page editor. */
const Text: React.FC<TextProps> = ({
	children,
	text,
	styleAs,
	as,
	margin = true,
	className = '',
	...htmlAttributes
}) => {
	const content = children || text;
	const Component: any = tagMapper[as];

	if (!content) {
		return null;
	}

	return (
		<Component
			className={clsx(
				typographyClasses[styleAs || as],
				margin && typographyMarginClasses[styleAs || as],
				className
			)}
			{...htmlAttributes}
		>
			{content}
		</Component>
	);
};

export default Text;
