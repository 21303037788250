import MapContext from 'components/Map/MapContext';
import { Tile } from 'ol/layer';
import { get } from 'ol/proj';
import { TileWMS } from 'ol/source';
import React, { useContext, useEffect, useState } from 'react';
import { DirectionMeter } from 'types/pageTypes/area_page';

interface Props {
	layer: {
		name: 'SlopeInclination' | 'SlopeAspect' | 'Polygon' | 'ExtraPolygon';
		url: string;
		params: Array<{ parameter: string; value: string }>;
	};
	activeDirections: Record<
		keyof Omit<DirectionMeter, 'title'>,
		{ state: boolean; code: string; label?: string }
	>;
	active: boolean;
}

const SlopeDirectionLayer: React.FC<Props> = ({
	layer,
	activeDirections,
	active,
}) => {
	const { map } = useContext(MapContext);

	const [layerSource, setLayerSource] = useState<TileWMS>();
	const [layerTile, setLayerTile] = useState<Tile>();

	useEffect(() => {
		if (!map) return;

		const paramObjectList = layer.params.map((e) => ({
			[e.parameter]: e.value,
		}));

		const mergedParamObjectList = Object.assign({}, ...paramObjectList);

		const newSlopeAspectSource = new TileWMS({
			projection: get('EPSG:3857'),
			params: mergedParamObjectList,
			url: layer.url,
		});

		const tempLayerTile = new Tile({
			source: newSlopeAspectSource,
			visible: active,
		});

		setLayerSource(newSlopeAspectSource);
		setLayerTile(tempLayerTile);

		map.addLayer(tempLayerTile);
	}, [map]);

	useEffect(() => {
		/**
		 * @param directions ActiveDirections
		 * @returns String combination of directions and weight of 0.7
		 */
		function getDirection(directions: typeof activeDirections): string {
			const directionList = Object.values(directions).filter((d) => d.state);

			// There is a risk here where when translated, label might change the params. Something to consider.
			const paramString = directionList
				.map((d) => `${d.label?.toLowerCase()}:0.7`)
				.join(';');

			return paramString;
		}

		if (layerSource && layerTile) {
			layerSource.updateParams({ ENV: getDirection(activeDirections) });
			layerTile.setSource(layerSource);
			layerTile.setVisible(active);
		}
	}, [activeDirections, active]);

	return null;
};

export default SlopeDirectionLayer;
