import React, { forwardRef } from 'react';
import clsx from 'clsx';
import Link from 'components/Link';

interface Props {
	linkTo?: string;
	className?: string;
	isLogoInMenu?: boolean;
	isMobile?: boolean;
	isInMenu?: boolean;

	/** ariaLabel & subtitle are used to logo accessibility */
	ariaLabel?: string;
	subtitle?: string;

	/** Callback method when the menu closes */
	setMenuIsOpen?: (value: React.SetStateAction<boolean>) => void;
	ref?: any;
}

const HeaderLogo: React.FC<Props> = forwardRef(
	(
		{
			linkTo,
			subtitle,
			className,
			setMenuIsOpen,
			isLogoInMenu = false,
			isMobile = false,
			ariaLabel,
			isInMenu,
		},
		ref: any
	) => {
		return (
			<div className="focus-visible:outline-2 focus-visible:outline focus-visible:outline-orange-400">
				<Link
					tabIndex={0}
					ref={ref}
					to={linkTo}
					onClick={() => setMenuIsOpen && setMenuIsOpen(false)}
					className={clsx(
						'my-2 mr-2 inline-flex transform scale-100',
						className
					)}
				>
					<div id="logo" className="h-8 md:h-12 mt-2 mr-2 sm:mr-4">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							xmlnsXlink="http://www.w3.org/1999/xlink"
							viewBox="0 0 165 150"
							preserveAspectRatio="xMinYMin"
							aria-label={ariaLabel}
							role="img"
							className="overflow-visible h-full"
						>
							<title id="logoTitle">Lavinprognoser Logo</title>
							<polygon
								fill="none"
								stroke="#EF5E31"
								strokeWidth="6"
								strokeMiterlimit="10"
								points="81.987,6.369 125.345,87.222 48.459,69.408"
							></polygon>
							<polygon
								id="logo-animated-block"
								fill="none"
								stroke="#EF5E31"
								strokeWidth="6"
								strokeMiterlimit="10"
								points="58.161,71.442 46.918,92.119 152.17,117.252 137.326,89.933"
							></polygon>
							<polygon
								fill="none"
								stroke="#EF5E31"
								strokeWidth="6"
								strokeMiterlimit="10"
								points="141.267,115.064 158.885,147.347 5.022,147.347 36.004,89.802"
							></polygon>
						</svg>
					</div>
					{!isInMenu && !isMobile && (
						<div
							className={`${
								isLogoInMenu ? 'banner-logo-title-in-menu' : 'banner-logo-title'
							} pt-2 md:pt-3`}
						>
							<>
								<svg
									aria-describedby="description"
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 745 56"
									preserveAspectRatio="xMinYMin"
									fill="#454542"
								>
									<path d="M1.52588e-05,55.433 L1.52588e-05,1.244 L12.093,1.244 L12.093,44.658 L35.195,44.658 L35.195,55.433 L1.52588e-05,55.433 Z"></path>
									<path d="M86.206,55.433 L81.166,43.727 L58.451,43.727 L53.414,55.433 L40.545,55.433 L63.958,1.244 L75.664,1.244 L99.076,55.433 L86.206,55.433 M69.848,17.369 L63.025,33.106 L76.592,33.106 L69.848,17.369 Z"></path>
									<path d="M119.387,35.742 L133.186,1.244 L146.289,1.244 L124.504,55.433 L114.271,55.433 L92.486,1.244 L105.589,1.244 L119.387,35.742 Z"></path>
									<path d="M154.737,1.244 L166.83,1.244 L166.83,55.433 L154.737,55.433 L154.737,1.244 Z"></path>
									<path d="M217.247,1.244 L229.34,1.244 L229.34,55.433 L217.247,55.433 L191.431,21.477 L191.431,55.433 L179.338,55.433 L179.338,1.244 L190.656,1.244 L217.247,36.13 L217.247,1.244 Z"></path>
									<path d="M278.916,6.089 C282.714,9.319 284.615,14.28 284.615,20.974 C284.615,27.668 282.663,32.563 278.761,35.665 C274.858,38.765 268.903,40.316 260.892,40.316 L253.682,40.316 L253.682,55.433 L241.588,55.433 L241.588,1.244 L260.737,1.244 C269.056,1.244 275.118,2.86 278.916,6.089 M270.039,27.408 C271.485,25.78 272.21,23.402 272.21,20.276 C272.21,17.149 271.268,14.926 269.38,13.609 C267.494,12.291 264.561,11.632 260.582,11.632 L253.682,11.632 L253.682,29.85 L261.822,29.85 C265.854,29.85 268.593,29.036 270.039,27.408 Z"></path>
									<path d="M338.494,19.229 C338.494,27.912 335.056,33.521 328.183,36.052 L341.904,55.433 L327.019,55.433 L315.001,38.145 L306.631,38.145 L306.631,55.433 L294.537,55.433 L294.537,1.244 L315.081,1.244 C323.504,1.244 329.513,2.666 333.105,5.508 C336.696,8.351 338.494,12.924 338.494,19.229 M323.917,25.664 C325.416,24.32 326.166,22.19 326.166,19.268 C326.166,16.35 325.39,14.346 323.84,13.26 C322.289,12.175 319.575,11.632 315.699,11.632 L306.629,11.632 L306.629,27.679 L315.467,27.679 C319.602,27.679 322.418,27.007 323.917,25.664 Z"></path>
									<path d="M394.853,47.952 C389.374,53.301 382.629,55.975 374.62,55.975 C366.607,55.975 359.863,53.3 354.386,47.952 C348.907,42.602 346.168,35.949 346.168,27.989 C346.168,20.031 348.907,13.376 354.386,8.027 C359.864,2.678 366.608,0.00299835 374.62,0.00299835 C382.63,0.00299835 389.375,2.678 394.853,8.027 C400.329,13.377 403.069,20.032 403.069,27.989 C403.069,35.949 400.329,42.603 394.853,47.952 M390.743,28.028 C390.743,23.195 389.193,19.073 386.092,15.662 C382.99,12.251 379.178,10.545 374.655,10.545 C370.132,10.545 366.323,12.251 363.22,15.662 C360.118,19.073 358.569,23.195 358.569,28.028 C358.569,32.862 360.118,36.97 363.22,40.355 C366.322,43.74 370.132,45.432 374.655,45.432 C379.178,45.432 382.989,43.74 386.092,40.355 C389.193,36.971 390.743,32.862 390.743,28.028 Z"></path>
									<path d="M448.964,27.835 L461.135,27.835 L461.135,47.061 C455.76,53.058 448.318,56.055 438.809,56.055 C430.85,56.055 424.168,53.42 418.768,48.148 C413.367,42.877 410.664,36.222 410.664,28.186 C410.664,20.15 413.419,13.443 418.923,8.068 C424.427,2.693 431.067,0.00599861 438.847,0.00599861 C446.624,0.00599861 453.383,2.564 459.118,7.681 L452.841,16.751 C450.411,14.632 448.176,13.172 446.136,12.371 C444.093,11.57 441.884,11.169 439.506,11.169 C434.906,11.169 431.029,12.732 427.878,15.859 C424.726,18.985 423.149,23.107 423.149,28.224 C423.149,33.341 424.661,37.436 427.686,40.512 C430.709,43.587 434.315,45.123 438.499,45.123 C442.687,45.123 446.174,44.323 448.966,42.721 L448.964,27.835 Z"></path>
									<path d="M510.287,1.244 L522.38,1.244 L522.38,55.433 L510.287,55.433 L484.472,21.477 L484.472,55.433 L472.379,55.433 L472.379,1.244 L483.697,1.244 L510.288,36.13 L510.287,1.244 Z"></path>
									<path d="M578.921,47.952 C573.442,53.301 566.697,55.975 558.688,55.975 C550.675,55.975 543.931,53.3 538.454,47.952 C532.975,42.602 530.235,35.949 530.235,27.989 C530.235,20.031 532.975,13.376 538.454,8.027 C543.932,2.678 550.676,0.00299835 558.688,0.00299835 C566.698,0.00299835 573.442,2.678 578.921,8.027 C584.398,13.377 587.137,20.032 587.137,27.989 C587.137,35.949 584.398,42.603 578.921,47.952 M574.811,28.028 C574.811,23.195 573.261,19.073 570.16,15.662 C567.058,12.251 563.246,10.545 558.723,10.545 C554.2,10.545 550.391,12.251 547.288,15.662 C544.186,19.073 542.637,23.195 542.637,28.028 C542.637,32.862 544.187,36.97 547.288,40.355 C550.39,43.74 554.2,45.432 558.723,45.432 C563.246,45.432 567.057,43.74 570.16,40.355 C573.261,36.971 574.811,32.862 574.811,28.028 Z"></path>
									<path d="M608.496,12.058 C607.334,13.014 606.752,14.28 606.752,15.857 C606.752,17.434 607.462,18.687 608.883,19.617 C610.306,20.547 613.586,21.645 618.729,22.911 C623.872,24.177 627.864,26.078 630.708,28.609 C633.548,31.142 634.971,34.837 634.971,39.694 C634.971,44.553 633.15,48.493 629.506,51.515 C625.863,54.538 621.068,56.053 615.124,56.053 C606.546,56.053 598.818,52.873 591.946,46.515 L599.156,37.677 C604.996,42.793 610.396,45.352 615.357,45.352 C617.58,45.352 619.323,44.875 620.591,43.917 C621.856,42.962 622.49,41.669 622.49,40.041 C622.49,38.414 621.816,37.123 620.473,36.165 C619.127,35.21 616.466,34.24 612.487,33.258 C606.182,31.759 601.569,29.808 598.649,27.405 C595.729,25.002 594.269,21.231 594.269,16.086 C594.269,10.945 596.117,6.977 599.812,4.186 C603.505,1.395 608.12,-1.90735e-06 613.651,-1.90735e-06 C617.268,-1.90735e-06 620.885,0.619999 624.504,1.86 C628.12,3.1 631.274,4.858 633.961,7.132 L627.837,15.969 C623.134,12.403 618.275,10.62 613.263,10.62 C611.247,10.624 609.659,11.102 608.496,12.058 Z"></path>
									<path d="M684.818,1.244 L684.818,12.019 L657.838,12.019 L657.838,23.183 L682.104,23.183 L682.104,33.494 L657.838,33.494 L657.838,44.735 L685.669,44.735 L685.669,55.433 L645.746,55.433 L645.746,1.244 L684.818,1.244 Z"></path>
									<path d="M740.867,19.229 C740.867,27.912 737.43,33.521 730.557,36.052 L744.279,55.433 L729.393,55.433 L717.377,38.146 L709.005,38.146 L709.005,55.433 L696.911,55.433 L696.911,1.244 L717.455,1.244 C725.879,1.244 731.887,2.666 735.479,5.508 C739.07,8.351 740.867,12.924 740.867,19.229 M726.293,25.664 C727.79,24.32 728.54,22.19 728.54,19.268 C728.54,16.35 727.764,14.346 726.214,13.26 C724.663,12.175 721.949,11.632 718.073,11.632 L709.002,11.632 L709.002,27.679 L717.841,27.679 C721.976,27.679 724.792,27.007 726.293,25.664 Z"></path>
								</svg>
							</>
							<p
								id="description"
								className="mt-2 inline-block text-small  text-gray-800 relative leading-4"
							>
								{subtitle}
							</p>
						</div>
					)}
				</Link>
			</div>
		);
	}
);

export default HeaderLogo;
