import React from 'react';
import {
	Altitude,
	DirectionMeter as DirectionMeterModel,
} from 'types/pageTypes/area_page';

import AltitudeMeter from 'components/AltitudeMeter';
import DirectionMeter from 'components/DirectionMeter';
import RichText from 'components/RichText';
import Link from 'components/Link';

interface Props {
	description: {
		title: string;
		content: string;
		url?: string;
		anchorUrl?: string;
	};
	altitude: Altitude;
	direction: DirectionMeterModel;
	information?: Array<{
		text: string;
		href: string;
		description: string;
	}>;
	position?: {
		heading: string;
		content: string;
	};
	isMobile?: boolean;
}

const Problem: React.FC<Props> = ({
	description,
	altitude,
	direction,
	information,
	position,
	isMobile,
}) => {
	return (
		<>
			<div className="flex mb-4">
				<DirectionMeter
					id={description.title.replace(/ /g, '')}
					{...direction}
					height="80px"
					width="80px"
					className="mr-6 text-3xl"
				/>

				<AltitudeMeter
					isMobile={isMobile}
					height="80px"
					amIInAccordion
					{...{ ...altitude, id: description.title.replace(/ /g, '') }}
				/>
			</div>
			{position && (
				<div className="mb-4">
					{position.heading && (
						<h3
							id={`problem-heading-${Math.floor(Math.random() * 1000000)}`}
							className="font-standard font-bold text-h4"
						>
							{position.heading}
						</h3>
					)}
					{position.content && (
						<RichText>
							<div dangerouslySetInnerHTML={{ __html: position.content }} />
						</RichText>
					)}
				</div>
			)}

			{information?.map((i, index) => (
				<div key={index} className="mb-6">
					<Link
						to={i.href}
						className="text-link underline hover:no-underline"
						fullReload
					>
						{i.text}
					</Link>
					<p>{i.description}</p>
				</div>
			))}

			<div className="mb-6">
				<Link
					to={description.url}
					className="text-link underline hover:no-underline"
					fullReload
				>
					{description.content}
				</Link>
			</div>
		</>
	);
};

export default Problem;
