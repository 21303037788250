export function mOverExpand(fsHtmlEle: HTMLImageElement, iconName: string) {
	fsHtmlEle.src = iconName;
	fsHtmlEle.setAttribute(
		'style',
		'background-color:#333; padding: 7px; border-radius: 0.25rem;'
	);
}

export function mOutCompress(fsHtmlEle: HTMLImageElement, iconName: string) {
	fsHtmlEle.src = iconName;
	fsHtmlEle.setAttribute('style', ' ');
}
