/**
 * MoreInformationBlock
 */

import React from 'react';
import { LinkWithImage } from 'types/global';
import clsx from 'clsx';
import { Grid, Cell } from 'components/Grid';
import Icon from 'components/Icon';
import LinkWithImageBlock from 'components/LinkWithImageBlock';
import { useMediaQuery } from 'react-responsive';

interface Props {
	/** Description of this property */
	heading: string;

	/** Optional text below heading */
	description?: string;

	/** Link list with images */
	links?: LinkWithImage[];

	/** If information icon should be shown in heading */
	showInfoIcon?: boolean;

	/** Size of link list items (size changes for image/text) */
	linkSize?: 'small' | 'large';
}

/** More Information block (as used in Legend). */
const MoreInformationBlock: React.FC<Props> = ({
	heading,
	description,
	links,
	showInfoIcon,
	linkSize = 'large',
	children,
}) => {
	const isMobile = useMediaQuery({
		maxWidth: 767,
	});

	return (
		<div
			className={clsx(
				linkSize === 'small' && 'mb-4',
				'px-0 lg:p-4 bg-white mt-10 sm:mt-0'
			)}
		>
			<div
				className={clsx(
					!isMobile && 'border-grey border-b-2 mb-8',
					isMobile && ' mb-4',
					'w-full flex justify-between flex-col items-start pb-8 '
				)}
			>
				<div
					className={clsx(
						'w-full mb-3 mt-0 sm:mt-4 flex',
						isMobile ? 'flex-col' : 'justify-between flex-row-reverse'
					)}
				>
					<div className={clsx(isMobile && 'flex justify-end')}>{children}</div>
					<h2
						id="more-info-block-heading"
						className="text-black text-xl leading-5 sm:text-h2 font-semibold"
					>
						{showInfoIcon && (
							<Icon
								size={isMobile ? 1 : 3}
								icon="circleExclamation"
								aria-hidden="true"
								direction="left"
								className="mr-4 -mt-2"
							/>
						)}

						{heading}
					</h2>
				</div>

				{description && (
					<p className="max-w-xl font-standard text-base mr-2 leading-6">
						{description}
					</p>
				)}
			</div>

			<Grid
				padding={false}
				className={clsx(linkSize === 'large' && 'justify-between')}
			>
				{links &&
					links.map((link: LinkWithImage, index: number) => (
						<Cell
							span={12}
							tablet={6}
							key={'link-' + index}
							className={clsx(linkSize === 'large' ? 'md:mr-0' : 'md:mr-8')}
						>
							<LinkWithImageBlock
								heading={link.heading}
								image={link.image}
								url={link.url}
								size={linkSize}
								index={index}
								key={'link-' + index}
							/>
						</Cell>
					))}
			</Grid>
		</div>
	);
};

export default MoreInformationBlock;
