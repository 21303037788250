/**
 * NewsBlock
 */

import React from 'react';
import clsx from 'clsx';

import { Link } from 'react-router-dom';
import { NewsBlock as NewsBlockType } from 'types/blocks';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Image from 'components/Image';

interface Props {
	/** NewsBlock heading */
	heading: string;

	/** Newsblock button */
	button?: {
		href: string;
		text: string;
	};

	/** Array of news blocks */
	newsBlocks: NewsBlockType[];
}

/** NewsBlock listing three news articles. */
const NewsBlock: React.FC<Props> = ({ heading, button, newsBlocks }) => (
	<div>
		<div className="w-full flex justify-between items-center mb-8">
			<h2 id="news-block-heading" className="text-h3 lg:text-h2 font-bold">
				{heading}
			</h2>

			{button && button.text && (
				<Button
					type="link"
					aria-label={'button ' + button.text}
					href={button.href}
					variant="secondary"
					className="group w-px-4-important flex flex-nowrap items-center"
				>
					<span className="text-base font-medium leading-5 no-underline group-hover:underline mr-2">
						{button.text}
					</span>
					<Icon icon="chevron" size={1} direction="left" />
				</Button>
			)}
		</div>

		{newsBlocks && (
			<div className="flex flex-wrap md:grid md:gap-8 block-grid w-full">
				{newsBlocks.map((block, index) => (
					<article
						key={index}
						id={`block-${index}`}
						className="bg-white rounded-lg border border-gray-200 shadow-md mb-8 md:mb-0"
						aria-labelledby={`block-heading-${index}`}
					>
						<Link to={block.url} className="group block">
							{block.image && (
								<div
									className={clsx(
										'w-full bg-grey-light',
										index !== 0 && 'md:hidden'
									)}
								>
									<Image 
									src={block.image.src}
									srcSet={block.image.srcSet}
									srcSetSizes={block.image.srcSetSizes}
									photographer={block.image.photographer}
									illustrator={block.image.illustrator}
									agency={block.image.agency}
									alt={block.image.alt}	
									className="block mx-auto w-full object-cover rounded-t-lg"
									/>
								</div>
							)}

							<div className="p-8">
								<span className="font-standard no-underline text-base leading-6 text-gray-800 pb-1 border-b-2 border-orange">
									{block.publishedDate}
								</span>
								<div className="mt-4">
									<h3
										id={`news-block-h3-${index}`}
										className="mb-2 text-2xl leading-8 font-bold tracking-tight text-black group-hover:underline flex items-baseline justify-between"
									>
										{block.heading}
										<Icon
											size={1}
											icon="chevron"
											direction="left"
											className="mr-2"
										/>
									</h3>
									<p className="card-p">{block.text}</p>
								</div>
							</div>
						</Link>
					</article>
				))}
			</div>
		)}
	</div>
);

export default NewsBlock;
