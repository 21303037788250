/**
 * AccordionItem
 */

import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useTransitionToggle, useUuid, useHash } from 'hooks';

import Icon from 'components/Icon';
import Image from 'components/Image';
import { useMediaQuery } from 'react-responsive';

export interface AccordionItemProps {
	/** The title/heading text for the accordion item. */
	title: string;

	/** Subtitle for accordion */
	subtitle?: string;

	/** Should the item be opened by default? */
	open?: boolean;

	/** Tag type to set the header to */
	as?: any;

	/** Callback when the item is toggled */
	onToggle?: any;

	/** Accordion id (if not set, a unique id is generated) */
	id?: string;

	/** Optional side image (rounded) */
	image?: any;

	/** boolean if is Risk scale data */
	isRiskScale?: boolean;

	/** Optional background color (risk mobile table) */
	backgroundColor?: string;

	/** Optional text color color (risk mobile table) */
	textColor?: string;
}

/** A single item inside an accordion. */
const AccordionItem: React.FC<AccordionItemProps> = ({
	title,
	subtitle,
	image,
	children,
	onToggle,
	open = false,
	as = 'h2',
	id,
	isRiskScale = false,
	backgroundColor,
	textColor,
}) => {
	const Heading = as;
	const hash = useHash();
	const [expanded, toggle, ref, Content] = useTransitionToggle(open);
	const [getUuid] = useUuid();
	const uniqueId = id || getUuid();
	const anchorId = id || `accordion-item-anchor-${uniqueId}`;
	const contentId = `accordion-item-content-${uniqueId}`;
	const buttonId = `accordion-item-button-${uniqueId}`;
	const timeout = 600;
	const smDevicesWidth = useMediaQuery({
		maxWidth: 400,
	});

	useEffect(() => {
		if (onToggle) {
			setTimeout(() => {
				onToggle(expanded);
			}, 600);
		}
		// eslint-disable-next-line
	}, [expanded]);

	useEffect(() => {
		if (open && !expanded) {
			toggle();
		}
		// eslint-disable-next-line
	}, [open]);

	useEffect(() => {
		if (hash === `#${anchorId}` && !expanded) {
			toggle();
		}
		// eslint-disable-next-line
	}, [hash]);

	return (
		<div className="first:mt-0 -mt-1">
			<Heading
				className="border-grey-light border-t-2 mb-1"
				id={`accordion-item-heading-${contentId}`}
			>
				{!isRiskScale && (
					<button
						aria-expanded={expanded}
						aria-controls={contentId}
						id={buttonId}
						onClick={toggle}
						className={clsx(
							'flex relative w-full items-center justify-between cursor-pointer',
							'font-standard bg-white text-black px-0 sm:-ml-2 sm:pr-2',
							image ? 'py-5' : 'py-8'
						)}
					>
						{image && (
							<div className="flex sm:ml-4">
								<Image
									src={image.src}
									alt={image.alt}
									className="w-12 h-12 rounded-3xl border-1 border-grey border-solid mr-6"
								/>
								<div className="flex flex-col text-left">
									<span className="text-grey-darker text-h5 font-semibold">
										{title}
									</span>

									{subtitle && (
										<span className="text-h4 font-bold text-orange">
											{subtitle}
										</span>
									)}
								</div>
							</div>
						)}

						{!image && (
							<span className="text-h4 font-bold text-black sm:ml-6">
								{title}
							</span>
						)}

						<Icon icon={expanded ? 'minus' : 'plus'} size={1} />
					</button>
				)}

				{isRiskScale && (
					<button
						aria-expanded={expanded}
						aria-controls={contentId}
						id={buttonId}
						onClick={toggle}
						className={clsx(
							'flex relative w-full items-center justify-between cursor-pointer',
							'font-standard bg-white text-black px-0 sm:-ml-2 sm:pr-2',
							'h-20'
						)}
					>
						{image && (
							<div className="flex h-20 justify-center items-center">
								<Image
									src={image.src}
									alt={image.alt}
									className="w-12 h-12 rounded-3xl border-1 border-grey border-solid mr-6"
								/>

								<div
									className="flex text-left flex-1 w-60 h-full justify-center items-center"
									style={{
										background: backgroundColor ? backgroundColor : '',
									}}
								>
									<span
										className="text-h5 font-semibold mr-4"
										style={{
											color: textColor ? textColor : '',
										}}
									>
										{title}
									</span>
									<span
										className="text-h5 font-semibold"
										style={{
											color: textColor ? textColor : '',
										}}
									>
										{subtitle}
									</span>
								</div>
							</div>
						)}

						{!image && (
							<span className="text-h4 font-bold text-black sm:ml-6">
								{title}
							</span>
						)}

						{smDevicesWidth ? (
							<div className="w-8 h-8 rounded-full bg-gray-300 absolute right-3 shadow-md">
								<Icon icon={expanded ? 'minus' : 'plus'} size={1} />
							</div>
						) : (
							<Icon icon={expanded ? 'minus' : 'plus'} size={1} />
						)}
					</button>
				)}
			</Heading>

			<div tabIndex={-1} id={anchorId}>
				<Content
					id={contentId}
					role="region"
					aria-labelledby={buttonId}
					expanded={expanded}
					containerRef={ref}
					timeout={timeout}
				>
					<div className="accordion-item-content py-2">{children}</div>
				</Content>
			</div>
		</div>
	);
};

export default AccordionItem;
