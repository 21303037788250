/**
 * Button
 */

import clsx from 'clsx';
import Link from 'components/Link';
import React from 'react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';

interface Props {
	/** Sets the size of the button */
	size?: 'large' | 'medium' | 'small';

	/** Sets the variant of the button */
	variant?: 'primary' | 'secondary';

	/** Button type */
	type?: 'button' | 'link' | 'externalLink';

	/** href */
	href?: string;

	/** Sets the width of the button */
	displayWidth?: 'dynamic' | 'fixed' | 'full';

	/** Pass optional classnames for the html */
	className?: string;

	/** On click handler */
	onClick?: (e: any) => void;

	/** Should the button be disabled */
	disabled?: boolean;

	/** Whether Button includes icon. False if empty. */
	icon?: 'close' | 'next' | 'previous' | string;

	[htmlAttributes: string]: any;
}

/** A reusable button component. */
const Button: React.FC<Props> = ({
	children,
	className = '',
	variant = 'primary',
	type = 'button',
	href,
	disabled = false,
	displayWidth = 'dynamic',
	onClick,
	icon = undefined,
	...htmlAttributes
}) => {
	function getIcon(iconType: typeof icon) {
		switch (iconType) {
			case 'close':
				return <MdClose aria-hidden />;

			case 'next':
				return <FaChevronRight aria-hidden />;

			case 'previous':
				return <FaChevronLeft aria-hidden />;

			default:
				return null;
		}
	}

	let ButtonType;
	switch (type) {
		case 'button':
			ButtonType = 'button';
			break;
		case 'link':
			ButtonType = Link;
			break;
		case 'externalLink':
			ButtonType = 'a';
			break;
	}

	return (
		<ButtonType
			disabled={disabled}
			className={clsx(
				className,
				'inline-block',
				'outline-none',
				'font-standard',
				'text-sm leading-6 relative py-3 px-8',
				'cursor-pointer no-underline rounded-md',
				variant === 'primary' && 'font-bold',
				disabled && 'bg-gray-300 text-white',
				variant === 'primary' && !disabled && 'bg-orange text-white',
				variant === 'secondary' && !disabled && 'bg-grey text-black',
				displayWidth === 'full' && 'w-full',
				displayWidth === 'fixed' && 'w-full md:w-button'
			)}
			onClick={onClick}
			{...htmlAttributes}
			href={href}
		>
			{icon ? (
				<div className="h-full flex items-center justify-center">
					{getIcon(icon)}
				</div>
			) : (
				children
			)}
		</ButtonType>
	);
};

export default Button;
