/**
 * Image
 */

import clsx from 'clsx';
import React from 'react';
import { IImage } from 'types/global';
import Link from 'components/Link';
import { useImageTitle } from 'hooks/useImageTitle';

export interface ImageProps extends IImage {
	/** The image figure caption */
	figCaption?: string;

	/** Optional classname for figure-tag. */
	figClassName?: string;

	/** Optional other html props will be spread on the component */
	[htmlAttributes: string]: any;
}

/** The image component with or without the figure caption. */
const Image: React.FC<ImageProps> = ({
	src,
	alt = "",
	className,
	figCaption,
	figClassName,
	title,
	link,
	loading = "eager",
	decoding = "auto",
	fetchpriority = "auto",
	srcSet,
	srcSetSizes,
	photographer,
	illustrator,
	agency,
	height,
	width,
	...htmlAttributes
}) => {

	const imgTitle = useImageTitle(photographer, agency, illustrator);

	if (!src) {
		return null;
	}

	return (
		<>
			{!figCaption && (
				<>
					{link ? 
						<Link target={link.target} rel={link.rel} title={link.text} href={link.url}>
							<img
								loading={loading}
								width={width}
								height={height}
								decoding={decoding}
								src={src}
								srcSet={srcSet}
								sizes={srcSetSizes}
								alt={alt}
								{...htmlAttributes}
								title={imgTitle != "" ? imgTitle : title}
								className={clsx(
									// !hasDimensions && 'w-full h-auto',
									className,
									'block',
								)}
								/>
						</Link>: 
						<img
							loading={loading}
							decoding={decoding}
							width={width}
							title={imgTitle != "" ? imgTitle : title}
							height={height}
							src={src}
							srcSet={srcSet}
							sizes={srcSetSizes}
							alt={alt}
							{...htmlAttributes}
							className={clsx(
								// !hasDimensions && 'w-full h-auto',
								className,
								'block',
							)}
						/>	
					}
				</>
			)}
			{figCaption && (
				<figure className={clsx('mb-4 relative', figClassName)}>
				{link ? 
					<Link target={link.target} rel={link.rel} title={link.text} href={link.url}>
						<img
							loading={loading}
							decoding={decoding}
							width={width}
							height={height}
							title={imgTitle != "" ? imgTitle : title}
							src={src}
							srcSet={srcSet}
							sizes={srcSetSizes}
							{...htmlAttributes}
							alt={alt}
							className={clsx(
								'w-full',
								className,
								'block',
								'mb-0'
							)}
						/>
					</Link>:
					<img
						loading={loading}
						decoding={decoding}
						width={width}
						height={height}
						src={src}
						srcSet={srcSet}
						title={imgTitle != "" ? imgTitle : title}
						sizes={srcSetSizes}
						{...htmlAttributes}
						alt={alt}
						className={clsx(
							'w-full',
							className,
							'block',
							'mb-0'
						)}
					/>
				}
				<figcaption className="py-2 text-black w-full text-sm">
					{figCaption}
				</figcaption>
			</figure>
			)}
		</>
	);
};

export default Image;
