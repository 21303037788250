/**
 * StartPage
 */

import React, { useEffect, useState } from 'react';
import { StartPageModel } from 'types/pages';
import { useMediaQuery } from 'react-responsive';
import clsx from 'clsx';
import Map from 'components/Map';
import AreaList from 'components/AreaList';
import NewsBlock from 'components/NewsBlock';
import Card from 'components/Card';
import Icon from 'components/Icon';
import Legend from 'components/Legend';

/** Page template for startpage. */
const StartPage: React.FC<StartPageModel> = ({
	heading,
	puffs,
	currentNews,
	announcement,
	areaPageLinksArea,
	map,
	_properties = {},
}) => {
	const [showMap, setShowMap] = useState(false);
	const [legendMobileIsVisible, setLegendMobileVisibility] = useState(false);
	const isMobile = useMediaQuery({
		maxWidth: 767,
	});
	const [hasSmallHeight, setHasSmallHeight] = useState<boolean>();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setHasSmallHeight(window?.innerHeight < 900);
		}
	}, [hasSmallHeight]);

	const handleMobileLegend = () => {
		setLegendMobileVisibility((prevState) => !prevState);
	};

	return (
		<>
			<h1 className="sr-only">{heading}</h1>

			{/* Legend Modal is here only for mobile  */}
			{isMobile && (
				<Legend
					{...map.legend}
					isVisible={legendMobileIsVisible}
					hide={handleMobileLegend}
				/>
			)}
			{!isMobile && (
				<div className="flex main-w-container xl:justify-between justify-center h-196 px-8 md-px-0 mt-16 mb-0">
					<Map
						{...map}
						handleMobileLegend={handleMobileLegend}
						isMobile={isMobile}
					/>

					<AreaList {...areaPageLinksArea} />
				</div>
			)}

			{isMobile && (
				<div
					className={clsx(
						'map-mobile sticky top-0 left-0',
						showMap &&
							(hasSmallHeight ? 'map-s-mobile-open' : 'map-mobile-open')
					)}
				>
					{showMap && (
						<Map
							{...map}
							handleMobileLegend={handleMobileLegend}
							isMobile={isMobile}
						/>
					)}
					{!showMap && (
						<Map
							{...map}
							handleMobileLegend={handleMobileLegend}
							isMobile={isMobile}
						/>
					)}
				</div>
			)}

			<div
				className={clsx(
					isMobile && (!showMap ? 'show-map-bg' : 'no-show-map-bg')
				)}
			>
				<div
					className={clsx(
						isMobile && 'mobile-container',
						'bg-white relative rounded-t-xl '
					)}
				>
					{isMobile && (
						<>
							<button
								//sectionLabel should be "Visa karta" or "See Map" depending the language
								aria-label={map.sectionLabel}
								className={
									'bg-grey-light flex justify-center px-2 py-2 w-full rounded-t-xl -mt-4'
								}
								onClick={() => setShowMap((prevVal) => !prevVal)}
							>
								<Icon
									icon="chevron"
									direction={showMap ? 'down' : 'up'}
									className="text-grey-darker"
								/>
							</button>

							<div className="p-6">
								<AreaList {...areaPageLinksArea} />
							</div>
						</>
					)}

					<div className="p-6 md:px-0">
						{puffs && (
							<div className="flex flex-wrap">
								{puffs.map((puff, pIndex) => (
									<Card
										id={puff.id}
										url={puff.url}
										image={puff.image}
										icon={puff.icon}
										heading={puff.heading}
										body={puff.body}
										key={pIndex}
									/>
								))}
							</div>
						)}

						{currentNews && <NewsBlock {...currentNews} />}
					</div>
				</div>
			</div>
		</>
	);
};

export default StartPage;
