/**
 * Field
 */

import React, { useState, useRef, useEffect } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { FieldProps } from '../types';

import { useFormValue } from '../context/Form.context';
import clsx from 'clsx';

const Field: React.FC<FieldProps> = (props) => {
	const {
		className,
		placeholder,
		description,
		onFieldChange,
		defaultValue,
		autoSubmit,
		name,
		id,
		showError,
		setFocus,
		visibleFields,
		noValidate,
		type,
		label,
		describedby,
		required,
		resetForm,
		patternMessage,
		validationMessage,
		...allProps
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState(defaultValue || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef<any>(null);
	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField = validateField(value);
		const fieldIsValid = !validatedField.message;

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		if (!noValidate) {
			if (touched && !fieldIsValid) {
				showFieldError(validatedField);
			} else if (
				(showError &&
					!fieldIsValid &&
					visibleFields &&
					visibleFields.includes(id)) ||
				(showError && !fieldIsValid && !visibleFields)
			) {
				setTouched(true);
				showFieldError(validatedField);
			} else {
				hideFieldError();
			}

			if (setFocus) {
				fieldRef.current.focus();
			}
		}
	}, [touched, showError, value, setFocus, visibleFields, required]);

	const onChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;

		setValue(target.value);
		onFieldChange({
			name,
			value: target.value,
			type: 'Field',
			autoSubmit,
		});
	};

	const describedByAttribute = `form${id}__desc${
		describedby ? ` ${describedby}` : ''
	}`;

	return (
		<input
			className={clsx(
				className,
				'h-8',
				'p-1',
				'border-2',
				'border-gray-400',
				'border-solid'
			)}
			title={description}
			placeholder={placeholder || ''}
			aria-invalid={!valid}
			value={value}
			name={name}
			required={required}
			onChange={onChange}
			onBlur={() => setTouched(true)}
			ref={fieldRef}
			id={id}
			aria-describedby={!noValidate ? describedByAttribute : undefined}
			type={type === null ? 'text' : type}
			{...allProps}
		/>
	);
};

export default Field;
