/**
 * MenuUl
 */
import React, { useState, useEffect, useRef } from 'react';
import Link from 'components/Link';
import Icon from 'components/Icon';
import clsx from 'clsx';

interface MenuUlProps {
	/** The pane level (1, 2, 3) */
	level: number;
	/** Main menu items */
	menuItems?: any;
	/** Callback method when the menu closes */
	setMenuIsOpen: (value: React.SetStateAction<boolean>) => void;

	setLastChildRef: (ref: React.RefObject<any>) => void;
}

/* Main description for this component. */
const MenuUl: React.FC<MenuUlProps> = ({
	menuItems,
	level,
	setMenuIsOpen,
	setLastChildRef,
}) => {
	return (
		<ul className="flex flex-col" tabIndex={-1}>
			{menuItems.map((link: any, index: number) => {
				const ref = React.createRef<any>();
				return (
					<MenuLi
						level={level}
						key={index.toString()}
						link={link}
						setMenuIsOpen={setMenuIsOpen}
						isFirstLi={index === 0}
						ref={ref}
						setLastRef={setLastChildRef}
					/>
				);
			})}
		</ul>
	);
};

export default MenuUl;

/**
 * MenuLi
 */

interface MenuLiProps {
	/** The link level (1, 2, 3) */
	level: number;
	link: any;
	/** Callback method when the menu closes */
	setMenuIsOpen: (value: React.SetStateAction<boolean>) => void;
	/** add margin top if its the 1st li */
	isFirstLi: boolean;

	setLastRef: (ref: any) => void;
}

/** Main description for this component. */
const MenuLi = React.forwardRef<any, MenuLiProps>(
	({ level, link, setMenuIsOpen, isFirstLi, setLastRef }, ref) => {
		const [child, setChild] = useState<any>(null);
		const [activeChildren, setActiveChildren] = useState<boolean>(false);
		const [optSelected, setOptSelected] = useState<boolean>(false);
		const [areaExpanded, setAreaExpanded] = useState<boolean>(false);
		useEffect(() => {
			if (link && link.active) {
				setActiveChildrens(link);
			}
		}, []);

		// set the last li Ref
		setLastRef(ref);

		const setActiveChildrens = (link: any) => {
			const childLinks = link.children || [];
			let links: any[] = [];
			if (level >= 2) {
				links = [...childLinks];
			} else {
				setOptSelected(true);
				links = childLinks;
			}

			setChild(
				<MenuUl
					level={level + 1}
					menuItems={links}
					setMenuIsOpen={setMenuIsOpen}
					setLastChildRef={setLastRef}
				/>
			);
		};

		const handleSetActiveChildren = (e: MouseEvent, link: any) => {
			e.preventDefault();

			if (!activeChildren) {
				setActiveChildrens(link);
			} else {
				setChild(null);
				setOptSelected(false);
			}

			setActiveChildren(!activeChildren);
			setAreaExpanded((prev: boolean) => !prev);
		};

		return (
			<li
				className={clsx(
					optSelected && 'border-l-4 border-orange-400',
					level === 1 && isFirstLi && 'mt-2'
				)}
			>
				<div
					className={clsx(
						'flex justify-between items-center',
						'border-b border-white',
						level === 1 ? 'font-semibold' : 'font-normal',
						level === 1 && 'bg-grey',
						level === 2 && 'bg-grey-light',
						level === 3 && 'bg-grey-lighter',
						level === 4 && 'bg-white'
					)}
				>
					{optSelected && (
						<div className="w-2 h-[4.5rem] border-l-8 border-orange-400"></div>
					)}
					<Link
						ref={ref}
						to={link.url}
						onClick={() => setMenuIsOpen(false)}
						className={clsx(
							'text-h5 mb-px py-4 focus:shadow-sm group flex items-center flex-grow',
							level === 1 && 'pl-8 py-5',
							level === 2 && 'pl-16',
							level === 3 && 'pl-20',
							level === 4 && 'pl-32'
						)}
					>
						<span className="no-underline group-hover:underline">
							{link.text}
						</span>
					</Link>

					{link.children && link.children.length > 0 && (
						<button
							ref={ref}
							className="h-12 py-2 px-4 border-transparent border-2 transition-all hover:border-orange rounded-lg"
							onClick={(e: any) => handleSetActiveChildren(e, link)}
							id={!child ? 'btnPlus' : 'btnMinus'}
							aria-expanded={areaExpanded}
						>
							{!child ? (
								<Icon
									icon="plus"
									aria-label={`Expandera undermeny för ${link.text}`}
								/>
							) : (
								<Icon
									icon="minus"
									aria-label={`Stäng undermeny för ${link.text}`}
								/>
							)}
						</button>
					)}
				</div>
				{child && child}
			</li>
		);
	}
);
