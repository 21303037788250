/**
 * MapContext
 */

import { Map } from 'ol';
import React from 'react';

type MapModel = {
	projection?: string;
	map: Map;
	// customControlIds: any[]
};

const MapContext = React.createContext<MapModel>({} as MapModel);

export const MapProvider: React.FC<MapModel> = ({
	map,
	projection,
	children,
}) => {
	return (
		<MapContext.Provider value={{ map, projection }}>
			{children}
		</MapContext.Provider>
	);
};

export default MapContext;
