import React from 'react';
import './tailwind.output.css';

import { useSelector } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RouteHandler } from './routeHandler';

import { useTabFocus } from 'hooks';
import { selectContent } from 'store/modules/model';
import { ContentTypes } from 'types/pages';

interface PropsInterface {
	Router: any;
}

export const routePatterns = ['/:siteRoute*'];

const getLocation = (pageContent: ContentTypes | undefined) => {
	let location = '';

	if (pageContent?.url) {
		location = pageContent.url;
	} else if (typeof window !== 'undefined') {
		location = window.location.pathname;
	}

	return location;
};

const App: React.FC<PropsInterface> = ({ Router }) => {
	const pageContent = useSelector(selectContent);
	const location = getLocation(pageContent);
	useTabFocus();

	return (
		<>
			<Router location={location} context={{}}>
				<Switch>
					{routePatterns.map((routePattern) => (
						<Route key={routePattern} path={routePattern}>
							<RouteHandler />
						</Route>
					))}
				</Switch>
			</Router>
		</>
	);
};

export default App;
