import { ModelLocalization } from "types/epi";

export const translate = (
	id: string,
	fallback: string,
	localization: ModelLocalization | undefined
) => {
	if (localization && id in localization.translations) {
		return localization.translations[id];
	}

	return fallback;
};