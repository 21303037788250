import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import React from 'react';

interface Props {
	title: string;
	active?: boolean;
	handleClick: () => void;
}

const ResetDirectionMeterControl: React.FC<Props> = ({
	title,
	handleClick,
	active = false,
}) => {
	return (
		<BaseCustomControl
			icon="reset"
			title={title}
			handleControl={handleClick}
			className="ol-reset-d-meter mb-0 ml-4"
			labelPosition="right"
			active={active}
		/>
	);
};

export default ResetDirectionMeterControl;
