/**
 * Link
 */

import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface Props {
	/** The url the link should navigate to */
	to?: string;

	/** The url the link should navigate to */
	href?: string;

	/** Pass optional classnames for the html */
	className?: string;

	/** Whether or not the page requires a full reload */
	fullReload?: boolean;

	[htmlAttributes: string]: any;
}

const Link = React.forwardRef<any, Props>(
	(
		{
			to,
			href,
			children,
			className = '',
			fullReload = false,
			...htmlAttributes
		},
		ref
	) => {
		const url = to || href || '#';

		if (fullReload || url.toLowerCase().includes('?')) {
			return (
				<a href={url} className={className} {...htmlAttributes} ref={ref}>
					{children}
				</a>
			);
		}

		return (
			<RouterLink to={url} className={className} {...htmlAttributes} ref={ref}>
				{children}
			</RouterLink>
		);
	}
);

export default Link;
