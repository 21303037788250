/**
 * NoContent
 */

import clsx from 'clsx';
import React from 'react';
import { AiOutlineExclamationCircle } from 'react-icons/ai';

interface Props {
	title: String;
	message: String;
}

/** Content Block for Page with No Content */
const NoContent: React.FC<Props> = ({ title, message }) => {
	return (
		<div
			className={clsx(
				'flex flex-col justify-center items-center',
				'bg-orange-100 bg-opacity-25',
				'border-solid border-1 border-orange-400',
				'rounded-lg',
				'p-8 my-8 md:my-0'
			)}
		>
			<AiOutlineExclamationCircle size={64} className="mb-4 text-orange-400" />
			<h1 className="text-h3 font-medium font-alt text-orange-400 mb-2">
				{title}
			</h1>
			<p className="font-medium text-orange-400 text-center max-w-3/4">
				{message}
			</p>
		</div>
	);
};

export default NoContent;
