/**
 * ErrorPage404
 */

import React from 'react';
import Button from 'components/Button';
import BannerImage from 'components/BannerImage';

import { IImage } from 'types/global';
import { Cell, Grid } from 'components/Grid';

interface Props {
	error?: string;
	heading: string;
	text?: string;
	buttonText?: string;
	linkTo: string;
	bannerImage?: IImage;
}

/** Page template for error handling. */
const ErrorPage404: React.FC<Props> = ({
	error,
	heading,
	text,
	buttonText = 'Tillbaka till hemsidan SW',
	linkTo = '/',
	bannerImage,
}) => {
	return (
		<article aria-labelledby="error-404-heading">
			{bannerImage && (
				<BannerImage heading={heading} image={bannerImage} />
			)}
			<div className="mx-auto main-w-container px-8 xl:px-0">
				<Grid className="flex justify-between">
					<Cell span={12} desktop={7}>
						<p className="text-base font-bold mb-6">{heading}</p>
						<p className="text-base font-normal mb-6">{text}</p>
						<Button
							type="link"
							href={linkTo}
							variant="secondary"
							className="group"
						>
							{buttonText}
						</Button>
					</Cell>
				</Grid>
			</div>
		</article>
	);
};

export default ErrorPage404;
