/**
 * Layer wrapper
 */

import { Tile as OLTileLayer } from 'ol/layer';
import { useContext, useEffect } from 'react';
import MapContext from '../MapContext';

type Props = {
	source: any;
};

const TileLayer: React.FC<Props> = ({ source }) => {
	const { map } = useContext(MapContext);

	useEffect(() => {
		if (!map) return;

		let tileLayer = new OLTileLayer({
			source,
		});

		map.addLayer(tileLayer);

		return () => {
			if (map) {
				map.removeLayer(tileLayer);
			}
		};
	}, [map, source]);
	return null;
};

export default TileLayer;
