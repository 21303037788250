/**
 * NewsListPage
 */

import React from 'react';
import { NewsListPageModel } from 'types/pages';

import Breadcrumb from 'components/Breadcrumb';
import BannerImage from 'components/BannerImage';
import Link from 'components/Link';
import Icon from 'components/Icon';

/** News list page (listing NewsPages). */
const NewsListPage: React.FC<NewsListPageModel> = ({
	heading,
	bannerImage,
	pages,
}) => {
	return (
		<div>
			<h1 className="sr-only">{heading}</h1>
			{bannerImage && <BannerImage heading={heading} image={bannerImage} />}

			<div className="mx-auto main-w-container px-8 xl:px-0">
				<Breadcrumb className="mt-1 mb-6" />

				<div className="w-full flex flex-wrap py-4 md-px-0">
					{pages.map((page, i) => (
						<article
							aria-labelledby={`heading-${page.id}`}
							key={i}
							className="w-full text-black py-5 border-t-2 last:border-b-2 border-grey-light"
						>
							<p className="text-p">{page.date}</p>

							<h2
								id={`news-list-page-heading-${i}`}
								className="text-xl font-bold leading-6 my-2 lg:my-4"
							>
								{page.heading}
							</h2>

							{page.body && <p className="text-p">{page.body}</p>}

							<Link
								to={page.link.url}
								className="mt-6 block group text-link underline hover:no-underline"
							>
								<span className="hover:no-underline">{page.link.text}</span>
								<span className="sr-only">"{page.heading}"</span>

								<Icon
									icon="chevron"
									size={1}
									className="ml-6"
									direction="left"
								/>
							</Link>
						</article>
					))}
				</div>
			</div>
		</div>
	);
};

export default NewsListPage;
