import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import React from 'react';

interface Props {
	title: string;
	active?: boolean;
	handleClick?: () => void;
}

const DecliningScaleControl: React.FC<Props> = ({
	title,
	handleClick,
	active = false,
}) => {
	function toggleDecliningScale() {}

	return (
		<BaseCustomControl
			icon="decliningScale"
			title={title}
			handleControl={handleClick ?? toggleDecliningScale}
			className="ol-declining-scale"
			labelPosition="left"
			active={active}
		/>
	);
};

export default DecliningScaleControl;
