import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import React from 'react';

interface Props {
	title?: string;
	handleClick?: () => void;
	active?: boolean;
}

const ForecastAreasControl: React.FC<Props> = ({
	title,
	handleClick,
	active = false,
}) => {
	function toggleForecastAreas() {}

	return (
		<BaseCustomControl
			icon="avalancheScale"
			title={title}
			handleControl={handleClick ?? toggleForecastAreas}
			className="ol-forecast-area"
			labelPosition="left"
			active={active}
		/>
	);
};

export default ForecastAreasControl;
