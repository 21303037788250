import React from 'react';
import { LabelProps, ErrorSpanProps } from './types';
import clsx from 'clsx';

export const Label: React.FC<LabelProps> = ({
	htmlFor,
	required,
	children,
}) => (
	<label className="block font-bold mb-1" htmlFor={htmlFor}>
		{children}
		{required && <abbr title="Obligatoriskt">*</abbr>}
	</label>
);

export const ErrorSpan: React.FC<ErrorSpanProps> = ({
	fieldId,
	invalid,
	children,
	className,
	...props
}) => (
	<span
		className={clsx(className, 'block', 'text-red-600')}
		id={`form${fieldId}__desc`}
		aria-live="polite"
		hidden={!invalid}
		{...props}
	>
		{children}
	</span>
);
