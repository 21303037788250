import React from 'react';

interface Props {
	className?: string;
	style?: React.CSSProperties;
}

const FooterMountain: React.FC<Props> = ({ className, style }) => {
	return (
		<svg
			role="img"
			aria-labelledby="mountainFooter"
			className={className}
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			width="843px"
			height="85px"
			viewBox="0 0 843 63"
			style={style}
			enableBackground="new 0 0 843 63"
			xmlSpace="preserve"
			xmlnsXlink="http://www.w3.org/1999/xlink"
		>
			<title id="mountainFooter">Mountain</title>
			<g id="Page-1">
				<g id="Group-Copy">
					<path
						id="Path-590-Copy"
						fill="#272723"
						d="M0,62.6L71,23l16.5,4L98,15.8l17,6.9l7.4-15.8l56.7,7
		l56.1-4.2l33,13l91.5-13.7l17.5,9.5l68.9-2.9L460.2,7l26.1,0.4L498.6,0l33,3.6l24.2,13l20.3-5.5l10.8,5.7l67.3,3.7l30.8-8.6
		l158.1,50.6"
					/>
					<path
						id="Path-591"
						fill="#151719"
						d="M685.7,12.1l13.6,29.6L655.5,20L685.7,12.1z"
					/>
					<path
						id="Path-592"
						fill="#151719"
						d="M575.8,10.9l-43.4,30.2L556,16.2L575.8,10.9z"
					/>
					<path
						id="Path-593"
						fill="#151719"
						d="M445.9,15.6l16.6-5.8l18.3,18.7l29.9-10.3l-15.2,23.6L534,22.3
		l-23.5,21.4l45.6-27.4L531.4,3.6L498.9,0l-13.7,7.4l-24.8-0.4L445.9,15.6z"
					/>
					<path
						id="Path-594"
						fill="#151719"
						d="M359.9,8.9l-36.3,45.9L377,17.9L359.9,8.9z"
					/>
					<path
						id="Path-595"
						fill="#151719"
						d="M122.8,6.5l18.5,34l-25.8-18.8L122.8,6.5z"
					/>
					<path
						id="Path-596"
						fill="#151719"
						d="M97.8,16.2L118,41.4l-29.9-15L97.8,16.2z"
					/>
					<path
						id="Path-597"
						fill="#151719"
						d="M70.9,23.4l12.8,19.4L4.6,62.2L70.9,23.4z"
					/>
					<path
						id="Path-598"
						fill="#151719"
						d="M269.2,23.2l-86.1,10.2l52.6-23.7L269.2,23.2z"
					/>
				</g>
			</g>
		</svg>
	);
};

export default FooterMountain;
