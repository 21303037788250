import BaseCustomControl from 'components/Map/Controls/CustomControls/BaseCustomControl';
import React from 'react';

interface Props {
	title?: string;
	active?: boolean;
	handleClick?: () => void;
}

const LegendControl: React.FC<Props> = ({
	title,
	handleClick,
	active = false,
}) => {
	function handleLegendVisibility() {}

	return (
		<BaseCustomControl
			icon={active ? 'close' : 'legend'}
			title={title}
			handleControl={handleClick ?? handleLegendVisibility}
			className="ol-legend"
			labelPosition="left"
			active={active}
		/>
	);
};

export default LegendControl;
