/**
 * Legend
 */

import React from 'react';
import { LinkWithImage } from 'types/global';
import MoreInformationBlock from 'components/MoreInformationBlock';
import Icon from 'components/Icon';

interface Props {
	/** Used to see if the Legend Sider should be visible or not */
	isVisible?: boolean;

	/* callback to close the modal */
	hide: () => void;

	/** Legend heading */
	heading: string;

	/** Legend description */
	description?: string;

	/** Legend links */
	links: LinkWithImage[];
}

/** Custom Modal of Legend. */
const Legend: React.FC<Props> = ({
	isVisible = false,
	heading,
	description,
	links,
	hide,
}) => {
	return (
		<>
			{isVisible && (
				<div
					tabIndex={-1}
					className="overflow-y-auto overflow-x-hidden fixed inset-0 z-22 bg-grey-darkest bg-opacity-75 justify-center items-baseline sm:items-center flex outline-none focus:outline-none"
					aria-modal="true"
					role="dialog"
				>
					<div className="relative mx-4 sm:mx-auto legend-modal bg-white shadow">
						<MoreInformationBlock
							heading={heading}
							description={description}
							links={links}
							linkSize="small"
							showInfoIcon
						>
							<button
								type="button"
								className="flex items-center justify-center px-2 min-w-menu-btn h-10 hover:bg-gray-200 focus:outline-primary outline-reverse-offset-1 rounded-md uppercase bg-grey"
								onClick={hide}
							>
								<span className="sr-only">Stäng {heading}</span>
								<Icon size={1} icon="remove" aria-hidden="true" />
							</button>
						</MoreInformationBlock>
					</div>
				</div>
			)}
		</>
	);
};

export default Legend;
