/**
 * GlossaryPage
 */

import React, { useEffect, useState } from 'react';
import BannerImage from 'components/BannerImage';
import Breadcrumb from 'components/Breadcrumb';
import { Cell, Grid } from 'components/Grid';
import Icon from 'components/Icon/Icon';
import { GlossaryPageModel } from 'types/pages';
import { smoothScrollToTarget } from 'utils/smoothScrollToTarget';
import { useLocation } from 'react-router-dom';
import GlossaryContent from 'components/GlossaryContent';
import GlossaryLetter from 'components/GlossaryLetter';
import { GlossaryTerm } from 'types/global';
import EpiFragments from 'components/EpiFragments';
import clsx from 'clsx';

/** Glossary Page for lavinprognoser */
const GlossaryPage: React.FC<GlossaryPageModel> = ({
	heading,
	preamble,
	text,
	bannerImage,
	glossaryTermModels,
}) => {
	const location = useLocation();
	const isEnglish = location.pathname.startsWith('/en/');
	const targetRef = React.useRef<HTMLDivElement>(null);

	const LETTERS = new Set('abcdefghijklmnopqrstuvwxyzåäö');
	const isValidLetter = () => {
		const validEntries: [string, GlossaryTerm[]][] = [];

		for (const key in glossaryTermModels) {
			if (LETTERS.has(key.toLowerCase())) {
				validEntries.push([key.toLowerCase(), glossaryTermModels[key]]);
			}
		}
		return validEntries;
	};
	const glossaryModel = isValidLetter(); // ask if glossaryTermModels has valid key/letter
	const [searcheableGlossary, setSearcheableGlossary] = React.useState<any[]>(
		[]
	);
	const [searchTerm, setSearchTerm] = useState('');
	const [noMatch, setNoMatch] = useState<boolean>();
	const [isActiveFocus, setIsActiveFocus] = useState<boolean>(false);
	const ariaLabelSearch = isEnglish
		? 'Search for a definition'
		: 'sök i lavinprognosers ordlista';

	const placeHolder = isEnglish ? 'Search' : 'Sök';

	// useEffect to listen smoothScrollToTarget function
	useEffect(() => {
		// Attach click event listeners to each anchor link
		const letterLink = document.querySelectorAll('.glossary-filter');
		letterLink.forEach((link) => {
			link.addEventListener('click', smoothScrollToTarget);
		});

		// Clean up the event listeners when the component is unmounted
		return () => {
			letterLink.forEach((link) => {
				link.removeEventListener('click', smoothScrollToTarget);
			});
		};
	}, []);

	// clean input & results
	const handleReset = () => {
		setNoMatch(false);
		setSearchTerm('');
		setSearcheableGlossary([]);
		setIsActiveFocus(false);
	};

	const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		setNoMatch(false);
		// Get the search term from the input field
		const filteredTxt = e.target.value.trimStart();

		// Update the search input with the new value
		setSearchTerm(filteredTxt);
		// Call search function with the new search term
		if (filteredTxt) {
			searchGlossaryMatch(filteredTxt);
		} else {
			// clean search values to show original data
			setSearcheableGlossary([]);
		}
	};

	const searchGlossaryMatch = (text: string) => {
		// Iterate through the glossaryValue to find the matching text
		const updatedSearchableGlossary = [];
		let currentKey: string = '';

		for (const [key, glossaryValue] of glossaryModel) {
			const foundTerms = glossaryValue.filter((termObj: any) => {
				return termObj.heading.toLowerCase().includes(text.toLowerCase());
			});
			if (foundTerms.length > 0) {
				setNoMatch(false);
				currentKey = key;
				updatedSearchableGlossary.push([currentKey, foundTerms]);
			}
		}
		if (updatedSearchableGlossary.length === 0) {
			setTimeout(() => {
				setNoMatch(true);
			}, 200);
		}

		setSearcheableGlossary(updatedSearchableGlossary);

		return null; // If not found, return null
	};

	return (
		<main aria-labelledby={isEnglish ? 'Glossary' : 'Ordlista'} ref={targetRef}>
			{bannerImage && (
				<BannerImage heading={heading} image={bannerImage} />
			)}
			<div className="mx-auto main-w-container px-8 xl:px-0">
				<Breadcrumb className="mt-1 mb-2" />

				<Grid className="flex-nowrap justify-between">
					<Cell span={12} desktop={7}>
						{preamble && <p className="text-base font-bold mb-6">{preamble}</p>}
						{text && <EpiFragments fragments={text.fragments} />}
						<fieldset className="my-4" aria-label={ariaLabelSearch}>
							<label className="w-full font-bold">{ariaLabelSearch}</label>
							<div
								className={clsx(
									'flex w-full h-12 mt-2 rounded-md border mb-4 justify-center items-center border-grey-dark',
									isActiveFocus ? 'shadow-lg' : ''
								)}
							>
								<input
									id="user-search"
									type="text"
									className="w-full h-[46px] py-2 pl-4 outline-none rounded-md"
									aria-label={isEnglish ? 'Search' : 'Sök'}
									placeholder={!isActiveFocus ? placeHolder : ''}
									value={searchTerm}
									onChange={handleSearch}
									onBlur={() => setIsActiveFocus(false)}
									onFocus={() => setIsActiveFocus(true)}
								/>
								{searchTerm.length > 0 ? (
									<Icon
										icon="remove"
										onClick={handleReset}
										className="cursor-pointer mr-4 ml-4"
										size={1}
										aria-label={isEnglish ? 'Clear search' : 'Rensa sökning'}
									/>
								) : (
									<Icon
										icon="search"
										className="mr-4"
										size={1}
										aria-label={isEnglish ? 'search icon' : 'sökikon'}
									/>
								)}
							</div>
						</fieldset>
						{noMatch && searcheableGlossary.length === 0 && (
							<p
								role="alert"
								className="w-full flex items-center rounded-md mb-4 p-3 text-red-500 border-red-500 border-1"
							>
								<Icon size={1} icon="iconWarning" className="mr-4" />

								{isEnglish ? 'No results!' : 'Inga resultat!'}
							</p>
						)}

						{searcheableGlossary.length > 0 ? (
							<>
								<p
									role="alert"
									className="w-full flex items-center rounded-md mb-4 p-3 text-grey-darker border-grey-light border-1"
								>
									{isEnglish
										? `found it ${searcheableGlossary.length} results`
										: `hittade ${searcheableGlossary.length} resultat`}
								</p>

								<ul className="flex w-full flex-wrap">
									{searcheableGlossary.map((alphabet, i: number) => {
										return (
											<GlossaryLetter
												alphabet={alphabet[0]}
												isEnglish={isEnglish}
												key={i.toString()}
											/>
										);
									})}
								</ul>

								{searcheableGlossary.map(
									([letter, glossaryContent], index: number) => {
										return (
											<GlossaryContent
												letter={letter}
												glossaryContent={glossaryContent}
												key={letter + index.toString()}
											/>
										);
									}
								)}
							</>
						) : (
							<>
								{glossaryModel && (
									<>
										<ul className="flex w-full flex-wrap">
											{glossaryModel.map((alphabet, i) => {
												return (
													<GlossaryLetter
														isEnglish={isEnglish}
														alphabet={alphabet[0]}
														key={i.toString()}
													/>
												);
											})}
										</ul>

										{glossaryModel.map(
											([letter, glossaryContent], index: number) => {
												return (
													<GlossaryContent
														letter={letter}
														glossaryContent={glossaryContent}
														key={letter + index.toString()}
													/>
												);
											}
										)}
									</>
								)}
							</>
						)}
					</Cell>
					{/* <Cell span={12} desktop={4}>
						// use this in case we want to have aside colum
					</Cell> */}
				</Grid>
			</div>
		</main>
	);
};

export default GlossaryPage;
