/**
 * EpiContentArea
 */

import React from 'react';
import {
	PuffModel,
	PuffWithImageModel,
	MoreInformationBlockModel,
} from 'types/blocks';

import { Cell, Grid } from 'components/Grid';
import { Puff, PuffWithImage } from 'components/Puff';
import MoreInformationBlock from 'components/MoreInformationBlock';

interface Props {
	/** Epi property containing content for the content area */
	content?: any;

	/** Epi properties for on page editing */
	[htmlAttributes: string]: any;
}

export const renderContent = (
	block: PuffModel | PuffWithImageModel | MoreInformationBlockModel,
	i: number
) => {
	switch (block.modelType) {
		case 'Puff':
			return (
				<Cell span={4} desktop={4} key={i}>
					<Puff {...block} />
				</Cell>
			);
		case 'PuffWithImage':
			return (
				<Cell span={8} desktop={8} key={i}>
					<PuffWithImage {...block} />
				</Cell>
			);
		case 'PuffWithLargeImage':
			return (
				<Cell span={12} desktop={12} key={i}>
					<PuffWithImage {...block} />
				</Cell>
			);
		case 'MoreInformationBlock':
			return (
				<Cell span={12} key={i}>
					<MoreInformationBlock {...block} />
				</Cell>
			);
		default:
			return null;
	}
};

/** Epi content area for handling blocks */
const EpiContentArea: React.FC<Props> = ({
	content = [],
	...htmlAttributes
}) => (
	<div {...htmlAttributes}>
		<Grid>{content.map(renderContent)}</Grid>
	</div>
);

export default EpiContentArea;
