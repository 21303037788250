/**
 * Puff
 */

import React from 'react';
import { PuffModel } from 'types/blocks';

import Text from 'components/Text';
import Link from 'components/Link';

/** Puff with text and link */
const Puff: React.FC<PuffModel> = ({
	id,
	heading,
	url,
	linkText,
	text,
	_properties = {},
}) => (
	<div
		className="block p-4 break-words"
		id={id}
		key={id}
		{..._properties?.block}
	>
		<Text as="h2" {..._properties?.heading}>
			{heading}
		</Text>
		<Text as="p" {..._properties?.text}>
			{text}
		</Text>

		{url && linkText && (
			<Link to={url} {..._properties?.url}>
				{linkText}
			</Link>
		)}

		{_properties?.linkText && (
			<Text as="p" {..._properties?.linkText}>
				{linkText}
			</Text>
		)}
	</div>
);

export default Puff;
