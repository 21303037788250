import React, { useEffect, useState } from 'react';
import Button from 'components/Button';
import { useMediaQuery } from 'react-responsive';
import Icon from 'components/Icon';
import { useSelector } from 'react-redux';
//import { selectSkipToTop } from 'store/modules/model';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

export interface Props {
	footerHeight: number;
}
type Fn = () => void;

const GoTopButton: React.FC<Props> = ({ footerHeight }) => {
	//const skipToTop: any = useSelector(selectSkipToTop);
	const fh = footerHeight;
	const [showGoTop, setShowGoTop] = useState(false);
	const [endOfThePage, setEndOfThePage] = useState(true);
	const isTablet = useMediaQuery({ query: '(max-width: 767px)' });
	const location = useLocation();
	const isEnglish = location.pathname.startsWith('/en/');
	let buttonName = isEnglish ? 'To the top' : 'Till toppen';

	const handleButtonState: Fn = () => {
		// show button
		if (window.scrollY > 100) {
			setShowGoTop(true);
			translateYcoord();
		} else {
			setShowGoTop(false);
		}
	};

	const translateYcoord: Fn = () => {
		const totalPageHeight: number = document.body.offsetHeight;
		const scrollPoint: number =
			window.scrollY + window.innerHeight + footerHeight;

		if (scrollPoint >= totalPageHeight - 100) {
			setEndOfThePage(true);
		} else {
			setEndOfThePage(false);
		}
	};

	const handleScrollUp: Fn = () => {
		window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
	};

	const handleTranslateY = () => {
		let total = endOfThePage ? -fh : 0;
		return `translateY(${total.toString()}px)`;
	};

	useEffect(() => {
		window.addEventListener('scroll', handleButtonState);

		return () => {
			window.removeEventListener('scroll', handleButtonState);
		};
	}, []);

	return (
		<div
			className={`fixed z-20 bottom-5 right-10
            ${showGoTop ? 'opacity-100' : 'opacity-0'}
            opacity-effect
        `}
			style={{
				transform: handleTranslateY(),
				transition: '0.3s ease-in',
				display: 'block',
			}}
		>
			{isTablet ? (
				<div
					onClick={handleScrollUp}
					className="flex justify-center items-center absolute right-4 bottom-0 bg-orange shadow-md font-semibold rounded-md whitespace-nowrap p-3"
				>
					<Icon
						icon="chevron"
						size={2}
						direction="down"
						aria-hidden
						className="text-white"
					/>
				</div>
			) : (
				<button
					onClick={handleScrollUp}
					className="flex absolute right-4 bottom-0 bg-orange shadow-md text-white font-semibold rounded-md cursor-pointer whitespace-nowrap py-3 px-6"
				>
					<Icon
						icon="chevron"
						size={2}
						direction="down"
						aria-hidden
						className="mr-2 text-white"
					/>
					{buttonName}
				</button>
			)}
		</div>
	);
};

export default GoTopButton;
