/**
 * GlossaryContent
 */

import EpiFragments from 'components/EpiFragments';
import React from 'react';

/** Main Glossary Content component */
const GlossaryContent: React.FC<any> = (props: any) => {
	const { letter, glossaryContent } = props;
	let uniqueKey = letter + Math.random().toString(36).substring(2, 9);

	return (
		<div key={uniqueKey}>
			<div className="w-22 h-22 bg-orange flex items-center justify-center mt-16 mb-6">
				<h1 className="text-white font-semibold uppercase" id={letter}>
					{letter}
				</h1>
			</div>

			{glossaryContent &&
				glossaryContent.map((glossaryItem: any, i: number) => {
					return (
						<div key={glossaryItem.heading + i.toString()} className="mb-8">
							<h2 id={glossaryItem.heading}>{glossaryItem.heading}</h2>
							<EpiFragments fragments={glossaryItem.text.fragments} />
						</div>
					);
				})}
		</div>
	);
};

export default GlossaryContent;
