// Function to handle smooth scrolling to the target element
export function smoothScrollToTarget(event: any) {
	event.preventDefault();
	const targetId = event.target.getAttribute('href')?.substring(1);

	const targetElement = document.getElementById(targetId);

	if (targetElement) {
		const offsetTop = targetElement.getBoundingClientRect().top;
		const targetPosition = offsetTop + window.scrollY - 150; // 150 because header component + 50 padding

		window.scrollTo({
			top: targetPosition,
			behavior: 'smooth',
		});
	}
}
