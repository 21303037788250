import React, { useState, useRef, useEffect } from 'react';
import { Transition } from 'react-transition-group';
import { TransitionState } from 'types/global';
import clsx from 'clsx';

interface UseTransitionToggle {
	(initialState: boolean, dropdown?: boolean): [boolean, () => void, any, any];
}

interface ContentWrapperProps {
	timeout?: number;
	expanded: boolean;
	containerRef: any;
	[htmlAttributes: string]: any;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({
	children,
	expanded,
	containerRef,
	timeout = 500,
	...htmlAttributes
}) => (
	<Transition in={expanded} timeout={timeout}>
		{(state: TransitionState) => (
			<div
				{...htmlAttributes}
				ref={containerRef}
				hidden={state === 'exited'}
				className={clsx(
					'relative overflow-hidden',
					state === 'entering' && 'animate-hidden-in',
					state === 'exiting' && 'animate-hidden-out'
				)}
			>
				<div className="relative overflow-hidden">{children}</div>
			</div>
		)}
	</Transition>
);

export const useTransitionToggle: UseTransitionToggle = (
	initialState,
	dropdown = false
) => {
	const [expanded, setExpanded] = useState(initialState || false);
	const containerRef = useRef<any>(null);

	const handleClickOutsideComponent = (e: MouseEvent) => {
		if (containerRef && !containerRef.current.contains(e.target) && expanded) {
			setExpanded(false);
		}
	};

	const toggle = () => setExpanded((expanded) => !expanded);

	useEffect(() => {
		if (dropdown) {
			document.addEventListener('click', handleClickOutsideComponent);
		}

		return () => {
			if (dropdown) {
				document.removeEventListener('click', handleClickOutsideComponent);
			}
		};
	});

	return [expanded, toggle, containerRef, ContentWrapper];
};
