import { MenuLink, FooterColumnItems, Link, Breadcrumb } from 'types/global';
import {
	LegendInfo,
	MapArea,
	MapControls,
	ReportedAvalanches,
} from 'types/mapTypes/MapModel';
import { ContentTypes } from 'types/pages';
import { DirectionMeter } from 'types/pageTypes/area_page';

/**
 * @fileoverview EPIServer Related Components
 */

declare global {
	interface Window {
		__model: any;
		epi: any;
	}
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace NodeJS {
		interface Global {
			window: any;
			document: any;
			Styled: any;
			React: any;
			ReactDOM: any;
			ReactDOMServer: any;
		}
	}
}

export interface EpiEvent {
	beta?: {
		isEditable: boolean;
		inEditMode: boolean;
		inPreviewMode: boolean;
		ready: boolean;
	};
}

export interface Model {
	head?: ModelHead;
	metadata?: ModelMetadata;
	header?: ModelHeader;
	localization?: ModelLocalization;
	breadcrumbs?: ModelBreadcrumb;
	map?: MapModel;
	content?: ContentTypes;
	footer?: ModelFooter;
}

interface ModelMetadata {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
}

interface ModelMetadata {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	twitter?: {
		card?: string | null;
		title?: string | null;
		description?: string | null;
		image?: string | null;
	};
	openGraph?: {
		title?: string | null;
		siteName?: string | null;
		type?: string | null;
		url?: string | null;
		description?: string | null;
		image?: string | null;
	};
}

export interface ModelLocalization {
	culture: string;
	translations: any;
}

interface ModelHead {
	title?: string | null;
	keywords?: string | null;
	description?: string | null;
	openGraphTitle?: string | null;
	openGraphImageUrl?: string | null;
	openGraphDescription?: string | null;
	twitterTitle?: string | null;
	twitterDescription?: string | null;
	twitterImageUrl?: string | null;
}

export interface ModelHeader {
	skipToContent: string;
	logo: {
		src: string;
		alt?: string;
		ariaLabel?: string;
		url: string;
		subtitle?: string;
	};
	lang: {
		items: Array<Link>;
	};
	menu: {
		type: string;
		closeText: string;
		menuItems: Array<MenuLink>;
	};
}

interface ModelBreadcrumb {
	breadcrumbs: Array<Breadcrumb>;
}

export interface MapModel {
	sectionLabel: string;
	centerCoordinates: number[];
	reportedAvalanches: ReportedAvalanches[];
	zoomLevel: number;
	maxZoomLevel: number;
	minZoomLevel: number;
	tileUrl: string;
	controls: MapControls;
	closeText: string;
	legend: LegendInfo;
	areas: MapArea[];
	directions: DirectionMeter;
	layers: Array<{
		name: 'SlopeInclination' | 'SlopeAspect' | 'Polygon' | 'ExtraPolygon';
		url: string;
		params: Array<{ parameter: string; value: string }>;
	}>;
	myPositionMarkerIconUrl: string;
	areaId?: number;
}

export interface ModelFooter {
	columns?: Array<{
		modelType: 'FooterColumnModel' | string;
		header: string;
		items: Array<FooterColumnItems>;
	}>;
	logo?: {
		modelType: string;
		src: string;
		alt: string;
	};
	logoText?: string;
	copyright?: string;
}

export interface ModelReact {
	containerId: string;
	clientOnly: boolean;
	siteBaseUrl: string;
	apiUrl: string;
	apiEditUrl: string;
	renderingState: RenderingStates;
}

export enum RenderingStates {
	ServerSide = 1,
	Hydrate,
	ClientSide,
}
