import Link from 'components/Link';
import React from 'react';

import { FooterColumnItems } from 'types/global';

interface Props {
	header: string;
	items: FooterColumnItems[];
	index: number;
}

const getModelType = (items: FooterColumnItems, index: number) => {
	switch (items.modelType) {
		case 'FooterTextModel':
			return (
				<p key={index} className="text-white flex items-center">
					{items.text}
				</p>
			);
		case 'FooterLinkModel':
			if (items.url?.includes('mailto:') || !items.url?.startsWith('/')) {
				return (
					<a
						key={index}
						href={items.url}
						className="text-white flex items-center underline hover:no-underline"
						target={items.newTab ? '_blank' : '_self'}
						rel="noopener noreferrer"
						aria-label={items.text}
						aria-describedby="footer-link-description"
					>
						{items.text}
					</a>
				);
			} else {
				return (
					<Link
						aria-describedby="footer-link-description"
						key={index}
						className="text-white flex items-center underline hover:no-underline"
						to={items.url}
					>
						{items.text}
					</Link>
				);
			}
		default:
			break;
	}
};

const FooterColumn: React.FC<Props> = ({ header, items, index }) => {
	return (
		<div className="footer-col-p footer-p-responsive">
			<h2
				id={`footer-col-heading-${index}`}
				className="mb-4 text-white text-h4 leading-5 font-standard font-semibold"
			>
				{header}
			</h2>

			<>{items.map(getModelType)}</>
		</div>
	);
};

export default FooterColumn;
