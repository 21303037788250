/**
 * AreaStatusLink
 */

import React from 'react';
import Icon from 'components/Icon';
import Link from 'components/Link';

interface Props {
	/** The url to the area page */
	url: string;

	/** Risk icon image */
	riskImage: any;

	/** Risk text */
	riskText: string;

	/** Area name for stated risk */
	areaName: string;

	/** Area index for id */
	index: number;
}

const AreaStatusLink: React.FC<Props> = ({
	url,
	riskImage,
	riskText,
	areaName,
	index,
}) => (
	<li
		id={areaName}
		className="w-full bg-grey-light mb-4 p-2 pt-0 rounded-md hover:shadow-md"
	>
		<Link href={url} className="flex justify-between items-center -mb-2 group">
			<div className="flex">
				<img
					src={riskImage.src}
					alt={riskImage.alt}
					aria-hidden="true"
					className="w-16 h-16 lg:w-20 lg:h-20"
				/>

				<div className="flex flex-col justify-center">
					<p className="text-small leading-5 text-black">{riskText}</p>
					<h3
						id={`area-status-link-${index}`}
						className="text-h5 lg:text-h4 leading-6 font-semibold group-hover:underline"
					>
						{areaName}
					</h3>
				</div>
			</div>

			<Icon size={1} icon="chevron" direction="left" className="mr-2" />
		</Link>
	</li>
);

export default AreaStatusLink;
