import { useEffect, useCallback, useState } from 'react';

interface UseHash {
	(): string;
}

export const useHash: UseHash = () => {
	const [hash, setHash] = useState(() =>
		typeof window !== 'undefined' ? window.location.hash : ''
	);

	const onHashChange = useCallback(() => {
		setHash(window.location.hash);
	}, []);

	useEffect(() => {
		window.addEventListener('hashchange', onHashChange);
		return () => {
			window.removeEventListener('hashchange', onHashChange);
		};
	}, []);

	return hash;
};
