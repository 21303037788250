/**
 * LangButton
 */

import React, { useState } from 'react';
import { Link } from 'types/global';
import { Link as RouterLink } from 'react-router-dom';
import Icon from 'components/Icon';

interface Props {
	/** Link contain languages properties */
	item: Link;

	/** Pass optional classnames for the html */
	className?: string;

	/** Callback method when the menu closes */
	setMenuIsOpen: (value: React.SetStateAction<boolean>) => void;

	/** this bool allow to know if we are in mobile device or not */
	isMobile?: boolean;
}

/* Switch language component. */
const LangButton: React.FC<Props> = ({
	item,
	className,
	setMenuIsOpen,
	isMobile,
}) => {
	const [showTooltip, setShowTooltip] = React.useState(false);
	const lang = item?.url?.startsWith('/en') ? 'en' : 'sv';

	return (
		<div className="flex flex-col items-center relative">
			{showTooltip && <span className="tool-lang">{item?.text}</span>}
			<a
				className="relative flex items-center overflow-hidden rounded-full transition-background hover:bg-gray-500 hover:shadow-md focus:outline-primary"
				id={lang + '-button'}
				href={item?.url || ''}
				aria-label={item?.text}
				onClick={() => setMenuIsOpen(false)}
				lang={lang}
				onMouseEnter={() => setShowTooltip(true)}
				onMouseLeave={() => setShowTooltip(false)}
				onFocus={() => setShowTooltip(true)}
				onBlur={() => setShowTooltip(false)}
			>
				<Icon
					size={isMobile ? 2 : 3}
					icon={item?.url?.startsWith('/en') ? 'enFlag' : 'svFlag'}
					aria-hidden="true"
				/>
			</a>
		</div>
	);
};

export default LangButton;
