/**
 * BannerImage
 */

import Image from 'components/Image';
import React from 'react';
import { IImage } from 'types/global';

interface Props {
	/** Banner image heading */
	heading: string;

	/** Banner image src */
	image: IImage;
}

/** Banner image component. */
const BannerImage: React.FC<Props> = ({ image, heading }) => (
	<header className="mb-16 md:mb-0">
		<div className="w-full h-top-image-mobile md:h-top-image bg-no-repeat bg-cover relative">
			<Image 
			src={image.src}
			srcSet={image.srcSet}
			srcSetSizes={image.srcSetSizes}
			photographer={image.photographer}
			agency={image.agency}
			illustrator={image.illustrator}
			alt={image.alt}
			className='absolute inset-0 w-full h-full object-cover z-0'/>
			<div className="mx-auto pointer-events-none main-w-container relative h-full block">
				<div className="bg-white p-8 rounded-lg inline-block left-0 right-0 absolute -bottom-4 md:bottom-8 md:left-8 md:right-unset xl:left-0">
					<h1 id="sp-heading" className="font-bold mb-0">
						{heading}
					</h1>
				</div>
			</div>
		</div>
	</header>
);

export default BannerImage;
