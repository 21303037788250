/**
 * LinkWithImageBlock
 */

import React from 'react';
import clsx from 'clsx';

import Link from 'components/Link';
import Icon from 'components/Icon';
import { IImage } from 'types/global';
import Image from 'components/Image';

export interface LinkProps {
	/** Link heading */
	heading: string;

	/** Link image */
	image: IImage;

	/** Link url */
	url: string;

	/** Link size (size changes for text/image) */
	size?: 'small' | 'large';

	/** index for id  */
	index: number;
}

/** Tiny image links block used by Legend */
const LinkWithImageBlock: React.FC<LinkProps> = ({
	heading,
	image,
	url,
	size = 'small',
	index,
}) => (
	<Link
		href={url}
		className={clsx(
			'shadow-card flex flex-wrap items-center rounded-lg bg-white no-underline hover:shadow-md group',
			size === 'small' ? 'md:h-card mb-2' : 'h-24 mb-6'
		)}
	>
		<div
			className={clsx(
				'bg-grey-light',
				size === 'small' ? 'w-20 h-card' : 'w-20 lg:w-32 h-24'
			)}
		>
			<Image
			src={image.src}
			srcSet={image.srcSet}
			srcSetSizes={image.srcSetSizes}
			alt={image.alt}
			loading="lazy"
			className='w-full h-full object-cover'
			/>
		</div>

		<div className="flex justify-between flex-1 items-center ml-3">
			<h3
				id={`link-with-image-block-title-${index}`}
				className={clsx(
					'no-underline font-bold font-standard group-hover:underline',
					size === 'large'
						? 'text-base lg:text-h4 px-4 leading-6'
						: 'text-base leading-5'
				)}
			>
				{heading}
			</h3>

			<Icon size={1} icon="chevron" direction="left" className="mr-5" />
		</div>
	</Link>
);

export default LinkWithImageBlock;
