/**
 * Grid
 */

import clsx from 'clsx';
import React from 'react';

// Interfaces
interface GridProps {
	/** Option to remove default grid padding */
	padding?: boolean;

	/** Option to remove default grid margin */
	margin?: boolean;

	/** Pass optional classnames for the html */
	className?: string;
}

interface CellProps {
	/** Specifies the number of columns the cell spans */
	span?: number;

	/** Specifies the number of columns the cell spans on a type of device (desktop) */
	desktop?: number;

	/** Specifies the number of columns the cell spans on a type of device (tablet) */
	tablet?: number;

	/** Pass optional classnames for the html */
	className?: string;

	/** Option to remove default grid margin */
	margin?: boolean;
}

const renderChildren = (children: any, margin: boolean) => {
	const renderedChildren = React.Children.map(children, (child) => {
		const props: any = child && child.props ? { ...child.props } : {};
		props.margin = margin;
		if (child) {
			return React.cloneElement(child, props);
		} else return false;
	});

	return renderedChildren;
};

/** Grid component using tailwind classes. */
const Grid: React.FC<GridProps> = ({
	padding = true,
	margin = true,
	className,
	children,
}) => {
	return (
		<div className="mx-auto max-w-site">
			<div
				className={clsx('flex flex-wrap', padding && 'lg:py-6 py-4', className)}
			>
				{renderChildren(children, margin)}
			</div>
		</div>
	);
};

const getWidthClass = (span: number, margin: boolean) => {
	if (!span) {
		return span;
	}
	if (span === 12) {
		return 'w-full';
	} else if (margin) {
		return `w-grid-${span}/12`;
	} else {
		return `w-${span}/12`;
	}
};

/** Grid Cell component using tailwind classes. */
const Cell: React.FC<CellProps> = ({
	span,
	desktop,
	tablet,
	className,
	margin = true,
	children,
}) => {
	return (
		<div
			className={clsx(
				className,
				margin && 'mb-4',
				span && getWidthClass(span, margin),
				desktop && `lg:${getWidthClass(desktop, margin)}`,
				tablet && `md:${getWidthClass(tablet, margin)}`
			)}
		>
			{children}
		</div>
	);
};

export { Grid, Cell };
