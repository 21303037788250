/**
 * Accordion
 */

import React from 'react';

const renderChildren = (children: any) => {
	const renderedChildren = React.Children.map(children, (child, index) => {
		if (!child) {
			return false;
		}
		const props: any = child && child.props ? { ...child.props } : {};

		return React.cloneElement(child, props);
	});

	return renderedChildren;
};

/** An accessible accordion used for toggling content. */
const Accordion: React.FC = ({ children }) => {
	return <div>{renderChildren(children)}</div>;
};

export default Accordion;
