/**
 * Breadcrumb
 */

import React from 'react';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { Breadcrumb as BreadcrumbModel } from '../../types/global';
import { selectBreadcrumbs } from 'store/modules/model';
import { useMediaQuery } from 'react-responsive';

import Link from 'components/Link';
import Icon from 'components/Icon';

interface Props {
	className?: string;
}

/** Breadcrumb component. */
const Breadcrumb: React.FC<Props> = ({ className }) => {
	const breadcrumbs = useSelector(selectBreadcrumbs);

	const isMobile = useMediaQuery({
		maxWidth: 768,
	});

	if (!breadcrumbs) {
		return null;
	}

	const nrOfBreadcrumbs: number = breadcrumbs?.breadcrumbs.length || 0;

	const breadcrumsToRender = isMobile
		? breadcrumbs?.breadcrumbs.filter((crumb, i) => i >= nrOfBreadcrumbs - 2)
		: breadcrumbs?.breadcrumbs;

	return (
		<nav className={clsx('flex py-4', className)} aria-label="Brödsmulor">
			<ul className="flex-wrap inline-flex items-center">
				{breadcrumsToRender.map(
					(breadcrumb: BreadcrumbModel, index: number) => (
						<li key={index} className="inline-flex items-center justify-center">
							{index > 0 && (
								<Icon
									aria-hidden
									icon="chevron"
									direction="left"
									className="text-grey-darker mx-4"
								/>
							)}
							<Link
								href={breadcrumb.url}
								className={clsx(
									'leading-5 inline-flex items-center font-normal font-standard text-base',
									breadcrumb.isActive
										? 'underline text-black'
										: 'text-grey-darker'
								)}
							>
								{breadcrumb.text}
							</Link>
						</li>
					)
				)}
			</ul>
		</nav>
	);
};

export default Breadcrumb;
