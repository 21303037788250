/**
 * ForecastListPage
 */

import ForecastListCard from 'components/ForecastListCard';
import React from 'react';
import { ForecastListPageModel } from 'types/pages';

/** This is the component for the page containing the overview of all available Forecasts */
const ForecastListPage: React.FC<ForecastListPageModel> = ({
	heading,
	items,
}) => {
	const forecastListHeadingID = 'forecastListHeading';

	return (
		<article aria-labelledby={forecastListHeadingID}>
			<h1
				id={forecastListHeadingID}
				className="text-h2 font-semibold text-grey-dark text-center mb-8"
			>
				{heading}
			</h1>

			<div className="flex flex-wrap gap-8 justify-center md:justify-start">
				{items?.map((forecast, index) => (
					<ForecastListCard index={index} key={index} {...forecast} />
				))}
			</div>
		</article>
	);
};

export default ForecastListPage;
