/**
 * Select
 */

import React, { useRef, useState, useEffect } from 'react';
import { useFieldValidation } from '../hooks/useFieldValidation';
import { useFormValue } from '../context/Form.context';
import { SelectProps } from '../types';

const Select: React.FC<SelectProps> = (props) => {
	const {
		id,
		name,
		options,
		defaultValue,
		description,
		onFieldChange,
		autoSubmit,
		showError,
		validationMessage,
		label,
		visibleFields,
		className,
	} = props;

	const [{ invalidFields }, dispatch] = useFormValue();
	const [value, setValue] = useState<any>(defaultValue || options[0] || '');
	const [valid, setValid] = useState(false);
	const [touched, setTouched] = useState(false);
	const fieldRef = useRef(null);

	const [validateField, showFieldError, hideFieldError] = useFieldValidation(
		fieldRef,
		props
	);

	useEffect(() => {
		const validatedField: any = validateField(value);

		if (validatedField.message) {
			setValid(false);
			dispatch({ type: 'FIELD_INVALID', field: validatedField });
		} else {
			setValid(true);

			if (invalidFields.includes(validatedField.id)) {
				dispatch({ type: 'FIELD_VALID', field: validatedField });
			}
		}

		if (touched && !valid) {
			showFieldError(validatedField);
		} else if (
			(showError && !valid && visibleFields && visibleFields.includes(id)) ||
			(showError && !valid && !visibleFields)
		) {
			setTouched(true);
			showFieldError(validatedField);
		} else {
			hideFieldError();
		}
	}, [touched, showError, value, invalidFields, visibleFields]);

	const onChange = (e: React.FormEvent<HTMLElement>) => {
		const target = e.target as HTMLFormElement;
		const selectedOption = target.options[target.selectedIndex];

		setValue(selectedOption.value || selectedOption.caption);
		onFieldChange({
			name,
			value: selectedOption.value || selectedOption.caption,
			type: 'Select',
			autoSubmit,
		});
	};

	return (
		<select
			id={id}
			name={name}
			title={description}
			aria-invalid={!valid}
			defaultValue={defaultValue}
			onChange={onChange}
			onBlur={() => setTouched(true)}
			ref={fieldRef}
			aria-describedby={`form${id}__desc`}
			className={className}
		>
			{options.map(({ value, caption }) => {
				return (
					<option value={value} key={value}>
						{caption}
					</option>
				);
			})}
		</select>
	);
};

export default Select;
