/**
 * FormContainerPage
 */

import React from 'react';
import EpiForms from 'components/EpiForms';
import { EpiFormsProps } from 'components/EpiForms/EpiForms';

import { Grid, Cell } from 'components/Grid';

/** Container page for showing Forms in Epi */
const FormContainerPage: React.FC<EpiFormsProps> = (props) => (
	<Grid>
		<Cell span={0} tablet={2} desktop={2}>
			&nbsp;
		</Cell>
		<Cell span={7}>{props.fields && <EpiForms {...props} />}</Cell>
	</Grid>
);

export default FormContainerPage;
