/**
 * AreaPage
 */

import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useMediaQuery } from 'react-responsive';
import { AreaPageModel } from 'types/pages';

import Problem from 'pages/AreaPage/components/Problem';
import DatePickerInput from 'components/DatePickerInput';
import Icon from 'components/Icon';
import Image from 'components/Image';
import Link from 'components/Link';
import Map from 'components/Map';
import NoContent from 'components/NoContent';
import RichText from 'components/RichText';
import { Accordion, AccordionItem } from 'components/Accordion';
import MoreInformationBlock from 'components/MoreInformationBlock';
import Button from 'components/Button';
import Legend from 'components/Legend';

/** This page contains the full description and information of a Forecast */
const AreaPage: React.FC<AreaPageModel> = ({
	forecast,
	heading,
	selectedDate,
	todayText,
	seePreviousForecastText,
	avalancheHazardText,
	forecastDateText,
	datepicker,
	historical,
	missingForecast,
	map,
	areaName,
	previewControls,
	moreInformationBlock,
	areaId,
}) => {
	const [showMap, setShowMap] = useState(false);

	const [legendMobileIsVisible, setLegendMobileVisibility] = useState(false);

	const isMobile = useMediaQuery({
		maxWidth: 767,
	});

	const [hasSmallHeight, setHasSmallHeight] = useState<boolean>();

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setHasSmallHeight(window?.innerHeight < 900);
		}
	}, [hasSmallHeight]);

	const handleMobileLegend = () => {
		setLegendMobileVisibility((prevState) => !prevState);
	};

	/** 
	 * 
		if there is not forecast (usually in summer) or is null  
	* */

	if (!forecast) {
		return (
			<>
				<div
					className={clsx(
						'my-8 py-4 border-b-2 border-gray-300 flex justify-between mx-5 lg:mx-0'
					)}
				>
					<div className="left-side">
						<span className="text-h4 font-medium leading-5 text-orange font-standard mb-3">
							{heading}
						</span>
						<h1
							id="area-name"
							className="text-2.5xl font-standard leading-8 font-medium"
						>
							{areaName}
						</h1>
						{historical?.isHistorical && (
							<p className="flex items-center w-128 rounded-md my-6 p-3 text-risk-4 border-red-500 border-1">
								<Icon size={1} icon="iconWarning" className="mr-4" />
								{historical.content}{' '}
								<Link
									href={historical.link.url}
									className="underline text-black mx-4"
									fullReload
								>
									{historical.link.text}
								</Link>
								<Icon icon="chevron" size={1} direction="left" />
							</p>
						)}
					</div>
				</div>
				<div className="flex main-w-container xl:justify-between justify-center h-auto sm:h-196 px-8 md-px-0 mt-16 mb-0 flex-wrap sm:flex-nowrap">
					<div className="max-w-md w-87">
						<NoContent
							title={missingForecast.title}
							message={missingForecast.text}
						/>
					</div>
				</div>
			</>
		);
	}

	const {
		validFrom,
		validFromPrefix,
		validToPrefix,
		validTo,
		riskImage,
		riskLabel,
		trend,
		avalancheProblem,
		assessmentHeading,
		assessmentContent,
		publishedDate,
		snowInfo,
		weatherInfo,
	} = forecast || {};

	const { trendLabel, trendImage, trendDate, trendContent, trendHeading } =
		trend || {};

	return (
		<>
			{isMobile && (
				<Legend
					{...map.legend}
					isVisible={legendMobileIsVisible}
					hide={handleMobileLegend}
				/>
			)}
			<>
				{isMobile && (
					<div
						className={clsx(
							'map-mobile sticky top-0 left-0',
							showMap &&
								(hasSmallHeight ? 'map-s-mobile-open' : 'map-mobile-open')
						)}
					>
						{showMap && (
							<Map
								{...map}
								handleMobileLegend={handleMobileLegend}
								isMobile={isMobile}
								areaId={areaId}
							/>
						)}
						{!showMap && (
							<Map
								{...map}
								handleMobileLegend={handleMobileLegend}
								isMobile={isMobile}
								areaId={areaId}
							/>
						)}
					</div>
				)}
				<div
					className={clsx(
						isMobile && (!showMap ? 'show-map-bg' : 'no-show-map-bg')
					)}
				>
					<div
						className={clsx(
							isMobile && 'mobile-container',
							!isMobile && 'main-w-container lg:mt-16',
							'relative bg-white rounded-t-xl '
						)}
					>
						{isMobile && (
							<>
								<button
									aria-label="Visa karta"
									className="bg-grey-light flex justify-center px-2 py-2 w-full rounded-t-xl -mt-4"
									onClick={() => setShowMap((prevVal) => !prevVal)}
								>
									<Icon
										icon="chevron"
										direction={showMap ? 'down' : 'up'}
										className="text-grey-darker"
									/>
								</button>
							</>
						)}
						<div
							className={clsx(
								isMobile ? 'pb-20' : 'pb-0',
								'h-auto flex-wrap px-8 md-px-0'
							)}
						>
							<div className="flex justify-between flex-wrap lg:flex-nowrap">
								<div className="mt-4 lg:mt-0">
									<span className="text-h4 font-semibold leading-5 text-orange font-standard">
										{heading}
									</span>
									<h1
										id="area-name"
										className="text-2.5xl font-standard leading-8 font-bold mt-2 sm:mt-3"
									>
										{areaName}
									</h1>
									<div className="flex flex-wrap mt-3 mb-4 lg:mb-0">
										<p className="text-h5 lg:text-h4 font-standard text-black mb-1 lg:mb-0">
											<span>{validFromPrefix} </span>
											<span>{validFrom}</span>
										</p>
										<span className="w-4 border-2 h-1 self-center -mt-1 lg:mt-0 mx-3 border-grey-darker bg-grey-darker"></span>
										<p className="text-h5 lg:text-h4 font-standard text-black">
											<span>{validToPrefix} </span>
											<span>{validTo}</span>
										</p>
									</div>
									<div className="mt-4">
										{publishedDate && (
											<DatePickerInput
												publishedDate={selectedDate}
												seePreviousForecastText={seePreviousForecastText}
											/>
										)}
									</div>

									{historical?.isHistorical && (
										<p className="inline-flex flex-wrap lg:flex-nowrap items-center rounded-md mb-6 mt-4 p-2 text-orange border-orange border-1">
											<Icon
												size={2}
												icon="iconWarning"
												className="mr-2 text-orange"
											/>
											<span>{historical.content}</span>

											<Link
												href={historical?.link.url}
												fullReload
												className="underline text-black mr-4 ml-8 lg:ml-4"
											>
												{historical.link.text}
												<Icon
													icon="chevron"
													size={1}
													direction="left"
													className="text-black ml-2"
												/>
											</Link>
										</p>
									)}

									{previewControls && (
										<div className="my-6 flex flex-wrap lg:flex-nowrap">
											{previewControls.previewButton && (
												<Button
													type="link"
													href={previewControls.previewButton.url}
													variant="secondary"
													className="group mr-4 mb-4 md:mb-0"
												>
													{previewControls.previewButton.text}
												</Button>
											)}

											<div>
												{previewControls.previousButton && (
													<Button
														type="link"
														href={previewControls.previousButton.url}
														variant="secondary"
														className="group h-12 mr-4"
													>
														<span className="sr-only">
															{previewControls.previousButton.text}
														</span>
														<Icon
															icon="chevron"
															size={1}
															direction="right"
															aria-hidden
														/>
													</Button>
												)}

												{previewControls.nextButton && (
													<Button
														type="link"
														href={previewControls.nextButton.url}
														variant="secondary"
														className="group h-12"
													>
														<span className="sr-only">
															{previewControls.nextButton.text}
														</span>
														<Icon
															icon="chevron"
															size={1}
															direction="left"
															aria-hidden
														/>
													</Button>
												)}
											</div>
										</div>
									)}
								</div>

								<div className="w-full sm:w-auto my-4 sm:mt-8 flex justify-between lg:justify-end -mb-12 sm:-mb-8 lg:mb-0">
									<div>
										<span className="text-sm lg:text-h4 font-semibold text-grey-darker">
											{avalancheHazardText}
										</span>
										<p className="text-h5 lg:text-2xl font-standard font-bold text-orange">
											{riskLabel}
										</p>
									</div>
									<div className="mb-0 flex -mr-9">
										{riskImage && (
											<img
												src={riskImage.src}
												alt={riskImage.alt}
												className="w-full h-full max-h-risk-icon max-w-risk-icon -mt-8"
											/>
										)}
									</div>
								</div>
							</div>

							<div className="mobile-w sm:w-full -ml-8 sm:ml-0 mt-4 border-1 border-grey" />

							<div className="max-w-6xl flex xl:justify-between justify-center h-auto lg:mx-auto mt-4 sm:mt-8 mb-0 sm:mb-12 flex-wrap md:flex-nowrap px-0">
								{!isMobile && (
									<Map
										{...map}
										handleMobileLegend={handleMobileLegend}
										isMobile={isMobile}
										areaId={areaId}
									/>
								)}

								<div className="area-right-col">
									<div className="py-4">
										{assessmentHeading && (
											<h2
												id="area-page-assessment-title"
												className="text-lg font-standard leading-6 font-semibold my-4"
											>
												{assessmentHeading}
											</h2>
										)}

										{assessmentContent && (
											<RichText>
												<div
													dangerouslySetInnerHTML={{
														__html: assessmentContent,
													}}
												/>
											</RichText>
										)}
									</div>

									<div className="h-auto">
										<Accordion>
											{avalancheProblem &&
												avalancheProblem.problems.length > 0 &&
												avalancheProblem.problems.map(
													(
														{
															heading,
															symbol,
															description,
															direction,
															altitude,
															position,
															information,
														},
														index
													) => (
														<AccordionItem
															title={heading}
															subtitle={description.title}
															image={symbol.image}
															key={index}
														>
															<Problem
																description={description}
																direction={direction}
																altitude={altitude}
																information={information}
																position={position}
																isMobile={isMobile}
															/>
														</AccordionItem>
													)
												)}

											{snowInfo && snowInfo.heading && (
												<AccordionItem title={snowInfo.heading}>
													<RichText className="mb-4">
														<div
															dangerouslySetInnerHTML={{
																__html: snowInfo.content,
															}}
														/>
													</RichText>
													{snowInfo.button && (
														<Link
															to={snowInfo.button.href}
															className="text-link underline hover:no-underline mb-4 block"
															fullReload
														>
															{snowInfo.button.text}
														</Link>
													)}
												</AccordionItem>
											)}

											{weatherInfo && weatherInfo.heading && (
												<AccordionItem title={weatherInfo.heading}>
													<RichText className="mb-4">
														<div
															dangerouslySetInnerHTML={{
																__html: weatherInfo.content,
															}}
														/>
													</RichText>
													{weatherInfo.button && (
														<Link
															to={weatherInfo.button.href}
															className="text-link underline hover:no-underline mb-4 block"
															fullReload
														>
															{weatherInfo.button.text}
														</Link>
													)}
												</AccordionItem>
											)}
										</Accordion>

										{trendHeading && trendLabel && (
											<div className="border-grey-light border-t-2 border-b-2 sm:mb-1 trend-heading-p">
												<div className="flex">
													<Image
														src={trendImage.src}
														alt={trendImage.alt}
														className="w-24 h-24 -ml-5 -mt-4"
													/>
													<div className="flex flex-col text-left ml-2 sm:ml-5">
														<h2
															id="area-page-how-you-read-an-avalanche-forecast"
															className="text-grey-darker text-h5"
														>
															{trendHeading}
														</h2>

														{trendLabel && (
															<p className="text-h4 font-bold text-orange">
																{trendLabel}
															</p>
														)}
														<p className="font-semibold mb-2">
															{forecastDateText} {trendDate}
														</p>
													</div>
												</div>

												{trendContent && (
													<div className="mt-4">
														<RichText>
															<div
																dangerouslySetInnerHTML={{
																	__html: trendContent,
																}}
															/>
														</RichText>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
							</div>
							{moreInformationBlock && (
								<MoreInformationBlock
									{...moreInformationBlock}
									linkSize={isMobile ? 'small' : 'large'}
								/>
							)}
						</div>
					</div>
				</div>
			</>
		</>
	);
};

export default AreaPage;
