import MapContext from 'components/Map/MapContext';
import { Tile } from 'ol/layer';
import { get } from 'ol/proj';
import { TileWMS } from 'ol/source';
import React, { useContext, useEffect, useState } from 'react';

interface Props {
	layer: {
		name: 'SlopeInclination' | 'SlopeAspect' | 'Polygon' | 'ExtraPolygon';
		url: string;
		params: Array<{ parameter: string; value: string }>;
	};
	active: boolean;
	areaId: number | undefined;
}

const ExtraPolygonLayer: React.FC<Props> = ({ active, layer, areaId }) => {
	const { map } = useContext(MapContext);

	const [layerTile, setLayerTile] = useState<Tile>();

	const areaQuery = `id IN (${areaId})`;

	useEffect(() => {
		if (!map) return;

		const paramObjectList = layer.params.map((e) => ({
			[e.parameter]: e.value,
		}));

		paramObjectList.push({ ['CQL_FILTER']: areaQuery });

		const mergedParamObjectList = Object.assign({}, ...paramObjectList);

		const forecastPolygonSource = new TileWMS({
			projection: get('EPSG:3857'),
			url: layer.url,
			params: mergedParamObjectList,
		});

		const tempTile = new Tile({
			source: forecastPolygonSource,
			visible: active,
		});

		setLayerTile(tempTile);

		map.addLayer(tempTile);
	}, [map, layer]);

	useEffect(() => {
		if (layerTile) layerTile.setVisible(active);
	}, [active]);

	return null;
};

export default ExtraPolygonLayer;
